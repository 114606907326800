import _ from "lodash";
import { TrendDown } from "phosphor-react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

//utils
import { ACTIONSTATE, APPCLASS, defaultTextValue } from "./enums";
import { getLocalStorageFlag, setLocalStorageFlag } from "./common";

//components
import i18nMessageBundle from "amdi18n-loader!./nls/i18n";

import { getCookie } from "../serviceWorker";

const [HbrIcon] = reactWrapper(["hbr-icon"]);

const uniquelyOrderedAppArray = [
  APPCLASS.OFFICE365,
  APPCLASS.WEBEX,
  APPCLASS.GOOGLE,
  APPCLASS.VOICE,
  APPCLASS.SALESFORCE,
  APPCLASS.GOTOMEETING
];

/**
 * Description: the method below format the properties of the argument to ensure
 * the data is display correct
 * @param {*} elem is an object the contains properties for
 * totalNumUsers, defaultQuality, recommendationQuality and userImpact
 */
export const getRecommendationFormatted = (elem, isPercentShown) => {
  if (!elem) {
    return {
      totalNumUsers: "--",
      defaultQuality: "--",
      recommendationQuality: "--",
      userImpact: "--",
      impactedUsers: "--",
      gains: 0
    };
  }

  const totalNumUsers =
    elem.totalNumHours === 0
      ? 0
      : Math.round(elem.totalNumUsers / elem.totalNumHours);

  const defaultQ = validateRecommandationValue(elem.defaultQuality);
  const recomQ = validateRecommandationValue(elem.recommendationQuality);
  const uImpact =
    elem.recommendationQuality - elem.defaultQuality === null
      ? 0
      : elem.recommendationQuality - elem.defaultQuality;
  const userImpactFloatFixed = parseFloat(
    (uImpact * elem.totalNumUsers) / 720
  ).toFixed(3);
  const impactedUsers = elem.numUsers ? elem.numUsers : 0;
  const userImpact =
    eval(userImpactFloatFixed) === 0 ? 0 : userImpactFloatFixed;
  const gains = computeGains(elem, isPercentShown);

  return {
    totalNumUsers: totalNumUsers,
    defaultQuality: defaultQ,
    recommendationQuality: recomQ,
    gains: gains,
    userImpact: userImpact,
    impactedUsers: impactedUsers
  };
};

export const formatRecommendationsFromAction = (
  action,
  formatedEndpoints,
  recomSummary = {}
) => {
  const defaultQ = 1 - action.predDefaultProbSlaViolation;
  const recomQ = 1 - action.predRecommendedProbSlaViolation;
  const location = formatedEndpoints[action.srcEndpointId]
    ? formatedEndpoints[action.srcEndpointId].location
    : { city: "--", country: "--" };
  const defaultQuality = validateRecommandationValue(defaultQ);
  const recommendationQuality = validateRecommandationValue(recomQ);
  const gains = computeGains(
    {
      defaultQuality: defaultQ,
      recommendationQuality: recomQ
    },
    false
  );
  const totalNumUsers =
    recomSummary[action.srcEndpointId] === undefined ||
    recomSummary[action.srcEndpointId]?.totalNumHours === 0
      ? 0
      : Math.round(
          recomSummary[action.srcEndpointId].totalNumUsers /
            recomSummary[action.srcEndpointId].totalNumHours
        );

  const impactedUsers = recomSummary[action.srcEndpointId]?.numUsers;
  const siteId = formatedEndpoints[action.srcEndpointId]?.siteId
    ? parseInt(formatedEndpoints[action.srcEndpointId].siteId)
    : parseInt(action.siteId);

  return {
    totalNumUsers: totalNumUsers,
    defaultQuality: defaultQuality,
    recommendationQuality: recommendationQuality,
    location: location,
    gains: gains,
    impactedUsers: impactedUsers,
    siteId: siteId
  };
};

export const validateRecommandationValue = value => {
  return value !== null ? `${(parseFloat(value) * 100).toFixed(1)}%` : "--";
};

export const computeGains = (elem, isPercentShown = true) => {
  const isEmpty =
    _.isUndefined(elem.recommendationQuality) ||
    _.isUndefined(elem.defaultQuality);

  return isPercentShown
    ? isEmpty
      ? `0`
      : `${(
          elem.recommendationQuality * 100 -
          elem.defaultQuality * 100
        ).toFixed(2)}`
    : isEmpty
    ? 0
    : `${(elem.recommendationQuality * 100 - elem.defaultQuality * 100).toFixed(
        2
      )}`;
};

export const recommandActionCards = (
  actionIdCards,
  endpoint,
  actionRecommendations
) => {
  const cards = [];
  if (!endpoint) {
    return cards;
  }
  const filterResult = actionIdCards.filter(
    action =>
      action.srcEndpointId === endpoint.endpointId &&
      isApplyRevertRecommendation(action)
  );

  // usually filterResult has length 1 since there is one recommendatin action per site
  if (!_.isEmpty(filterResult)) {
    filterResult.forEach(filter => {
      const cardObject = actionRecommendations.filter(
        recommendation => recommendation.actionId === filter.actionId
      );

      if (_.isEmpty(filter) || _.isEmpty(actionRecommendations)) {
        cards.push({
          ...filter,
          defaultQuality: "--",
          recommendationQuality: "--",
          gains: "--",
          impactedUsers: "--"
        });
      } else {
        const defaultQ = 1 - filter.predDefaultProbSlaViolation;
        const recomQ = 1 - filter.predRecommendedProbSlaViolation;

        cards.push({
          ...filter,
          defaultQuality: validateRecommandationValue(defaultQ),
          recommendationQuality: validateRecommandationValue(recomQ),
          gains:
            `+` +
            computeGains({
              defaultQuality: defaultQ,
              recommendationQuality: recomQ
            }),
          impactedUsers: _.isEmpty(cardObject) ? 0 : cardObject[0].numUsers
        });
      }
    });
  }
  return cards;
};

/**
 * description: create an object appGroupRecomdtnsPerSingleSiteObj from responses[0] and resultPathsPairTwo
  where we collect pathId and path recommendation details for this path
  details such as total users, default quality, recommendation
 * @param {*} data is a response from api that need to be processed
 * @param {*} appGroupRecomdtnsPerSingleSiteObj create an object that collects pathId and
 *   recommendation for total users, default quality, and recommendation path details
 */
export const addRecommendationToPaths = (
  data,
  appGroupRecomdtnsPerSingleSiteObj
) => {
  if (data)
    data.forEach(elem => {
      const {
        totalNumUsers,
        defaultQuality,
        recommendationQuality,
        gains,
        userImpact,
        impactedUsers
      } = getRecommendationFormatted(elem, false);
      appGroupRecomdtnsPerSingleSiteObj[elem.pathId] = {
        ...{
          pathRecommendation: {
            totalNumUsers: totalNumUsers,
            defaultQuality: defaultQuality,
            gains: gains,
            impactedUsers: impactedUsers,
            recommendationQuality: recommendationQuality,
            userImpact: userImpact
          },
          source: { siteId: "--", hostname: "--" },
          destination: { siteId: "--", hostname: "--" }
        }
      };
    });
  return appGroupRecomdtnsPerSingleSiteObj;
};

/**
 *
 * @param {*} responses is list of api calls responses to extract path metrics for local and remote devices
 * @param {*} childEnpointId is an object that contains such property as endpointId(parent id) and symEnpointsArray of children ids
 */
export const createLocalRemoteDevicesObject = (responses, childEnpointId) => {
  let appGroupRecomdtnsPerSingleSiteObj = {};

  appGroupRecomdtnsPerSingleSiteObj = responses[1].data
    ? addRecommendationToPaths(
        responses[1].data.recoPathMetrics,
        appGroupRecomdtnsPerSingleSiteObj
      )
    : {};

  const generateSourceDestinationObject = (source, destination, pathId) => {
    const location = formatLocationToString(
      destination && destination.location
    );
    return {
      ...{
        ...appGroupRecomdtnsPerSingleSiteObj[pathId],
        source: {
          hostname: source.metadata.hostname,
          siteId: source.metadata.siteId
        },
        destination: {
          hostname: destination.metadata.hostname,
          siteId: destination.metadata.siteId,
          location: location
        },
        pathQosPair: `${source.endpointId},${destination.endpointId}`
      }
    };
  };

  /*
    For the object we created above appGroupRecomdtnsPerSingleSiteObj,
    we need to add source and destinatin for each path
    we have scenario where the endpoint has 2 children (endpoint.symEnpointsArray.length === 2 )
    and one child (endpoint.symEnpointsArray.length === 1 )
    */
  responses[0].data.paths.forEach(elem => {
    const symEndpointTwo = getLocalStorageFlag("symEndpointTwo");
    const isSecondChildEndpoint = !_.isEmpty(symEndpointTwo);
    if (
      elem.srcEndpointId === childEnpointId ||
      (isSecondChildEndpoint && elem.srcEndpointId === symEndpointTwo)
    ) {
      if (appGroupRecomdtnsPerSingleSiteObj[elem.pathId]) {
        appGroupRecomdtnsPerSingleSiteObj[
          elem.pathId
        ] = generateSourceDestinationObject(
          elem.srcEndpoint,
          elem.dstEndpoint,
          elem.pathId
        );
      }
    } else if (
      elem.dstEndpointId === childEnpointId ||
      (isSecondChildEndpoint && elem.srcEndpointId === symEndpointTwo)
    ) {
      if (appGroupRecomdtnsPerSingleSiteObj[elem.pathId]) {
        appGroupRecomdtnsPerSingleSiteObj[
          elem.pathId
        ] = generateSourceDestinationObject(
          elem.dstEndpoint,
          elem.srcEndpoint,
          elem.pathId
        );
      }
    }
  });
  return appGroupRecomdtnsPerSingleSiteObj;
};
/**
 * location is an object of city and country
 * format it to string
 * @param {*} location
 */
export const formatLocationToString = location => {
  return location?.country || location?.city
    ? `<div style="width: 200px">${location?.country} · ${location?.city}</div>`
    : `<div style="width: 200px">--</div>`;
};

/**
 * Description: this method verify if the application is a valid one meaning it is included in the supported application
 * As today we support 6 applications names: voice, salesforce, webex, gotomeeting, google workspace, office365
 * @param {*} listOfApplications  is an array that contains the list of appClass name applications
 */
export const validateAppClass = listOfApplications => {
  const orderedActiveAppClassArrray = [];
  const currActiveAppClassesArray = [];
  const currActiveAppClassesObject = {};
  const orderedAppArray = [];

  listOfApplications?.forEach(application => {
    if (application.isCurrentlyActive) {
      //get the list of currently active app classes
      currActiveAppClassesArray.push(application.appClassId);
      //create object that map appClassId to its app class name
      currActiveAppClassesObject[application.appClassId] = application.name;
      //firstly, the list is ordered by currently active app classes and isDefault=false
      if (
        !orderedAppArray.includes(application.appClassId) &&
        !application.isDefault
      )
        orderedAppArray.push(application.appClassId);
    }
  });

  //secondly, add currently active app classes that have isDefault=true
  // and app classes are ordered as like: office, webex, google, salesforce, gotomeeting, voice
  uniquelyOrderedAppArray.forEach(appClassId => {
    if (
      currActiveAppClassesArray.includes(appClassId) &&
      !orderedAppArray.includes(appClassId)
    ) {
      orderedAppArray.push(appClassId);
    }
  });

  //thirdly, add currently active app classes that have isDefault=true and are not from the list
  // described in uniquelyOrderedAppArray, that contains office, webex, google, salesforce, gotomeeting, voice
  currActiveAppClassesArray.forEach(appClassId => {
    if (!orderedAppArray.includes(appClassId)) {
      orderedAppArray.push(appClassId);
    }
  });

  //sort currently active app classes in specific order like: office, webex, google, salesforce, gotomeeting, voice,
  //then if, there are appclasses with isDefault=false, prefer them, and then the isDefault=true
  orderedAppArray.forEach(appClassId => {
    if (currActiveAppClassesArray.includes(appClassId))
      orderedActiveAppClassArrray.push({ appClassId: appClassId });
  });

  setLocalStorageFlag(
    "activeAppClasses",
    JSON.stringify(currActiveAppClassesObject)
  );

  return orderedActiveAppClassArrray;
};

/**
 * Returns one property as a string
 * @description this method checks if the prop is defined, if it is undefined than
 * we check the local storage for that value and replace  the prop with it
 * @param {prop} is a string or undefined value
 * @param {storedKey} is a string of the key of the local storage value, i.e. 'currentOverlay'
 * @return prop as a string
 */
export const validate = (prop, storedKey) => {
  const storedProp = localStorage.getItem(storedKey);
  if (_.isEmpty(prop)) {
    if (_.isEmpty(storedProp)) {
      return "";
    } else {
      return storedProp;
    }
  } else {
    return prop;
  }
};

/**
 * Returns an object of list of properties that are valid, meaning the props are not null or undefined
 * @description globalFilter parameter has list of props that we want to check if the props are null or undefined
 * if so we take the value of props from the local storage
 * @param {object} props is an object that contains list of properties such as
 * selectedSite, appGroup, endpoints, selectedOverlay, globalAltoId, latestWindow
 */
export const validateProps = props => {
  const globalAltoId = validate(props.globalAltoId, "globalAltoId");
  const appGroup = validate(
    props.appGroup === "all-apps-group" ? "" : props.appGroup,
    "appGroup"
  );
  const latestWindowStart = validate(
    props.latestWindow && props.latestWindow.start,
    "latestWindowStart"
  );
  const latestWindowEnd = validate(
    props.latestWindow && props.latestWindow.end,
    "latestWindowEnd"
  );
  const onboardingStatus = validate(props.status, "onboardingStatus");
  const selectedOverlay = validate(props.selectedOverlay, "currentOverlay");
  const selectedSite = validate(props.selectedSite, "currentSite");

  return {
    globalAltoId: globalAltoId,
    appGroup: appGroup,
    overlays: props.overlays,
    selectedSite: parseInt(selectedSite),
    latestWindowStart: latestWindowStart,
    latestWindowEnd: latestWindowEnd,
    onboardingStatus: onboardingStatus,
    selectedOverlay: selectedOverlay
  };
};

export const getInfoIcon = (reference, tooltipMessage) => {
  return `
        <i class="dnac-icon-info"
          tabindex="0"
          aria-label="information"
          id="${reference}"
        </i>
        <dnx-tooltip-lite
          reference="#${reference}"
          placement="bottom"
        >
          <div class="my-table-tooltip" style="max-width: 300px;">
            ${tooltipMessage}
          </div>
        </dnx-tooltip-lite>
      `;
};

export const formatActionStateLabel = action => {
  switch (true) {
    case (action.curState == ACTIONSTATE.NON_APPLIED ||
      action.curState == ACTIONSTATE.APPLY_REQUEST) &&
      action.isActive:
      return i18nMessageBundle.commonUtilappGroupPerSite.actionStateApply;
    case action.curState == ACTIONSTATE.APPLIED ||
      action.curState == ACTIONSTATE.REVERT_REQUEST:
      return i18nMessageBundle.commonUtilappGroupPerSite.actionStateRevert;
    case action.curState == ACTIONSTATE.NON_APPLIED && !action.isActive:
      return i18nMessageBundle.commonUtilappGroupPerSite.actionStateDeleted;
    default:
      return "--";
  }
};

export const formatEndpointsById = (endpoints, selectedSite = -1) => {
  let objectEndpoint = {};

  endpoints.forEach(elem => {
    const isFilterSite =
      selectedSite === -1
        ? elem.aggregatedBy === "siteId" && elem.endpointType !== "saas-dc"
        : elem.aggregatedBy === "siteId" &&
          elem.endpointType !== "saas-dc" &&
          selectedSite.toString() === elem.siteId;

    if (isFilterSite) {
      let location, lat, long;

      // check if site location is known or unknown
      if (
        elem?.location?.latitude === undefined ||
        elem?.location?.latitude > 90
      ) {
        lat = 0;
        long = 0;
      } else {
        lat = elem.location.latitude;
        long = elem.location.longitude;
      }

      if (elem.location?.country && elem.location?.city) {
        location = `<div style="width: 200px">${elem.location.country} · ${elem.location.city}</div>`;
      } else {
        location = `<div>${"--"}<div>`;
      }

      objectEndpoint = {
        ...objectEndpoint,
        ...{
          [elem.endpointId]: {
            siteId: elem.siteId,
            location: location,
            latitude: lat,
            longitude: long,
            endpointId: elem.endpointId
          }
        }
      };
    }
  });
  return objectEndpoint;
};

export const isRecommendationPage = pathName => {
  return pathName === "Recommendations";
};

/**
 * Returns an object with key as an endpoint and value as an object that has destination endpoint and symEnpointsArray of selected endpoints
 * @description creates a new object with source and destinaions endpoints
 * @param {string} endpoints
 * @return {object} an object with key as an endpoint and value as an object with destination endpoint
 */
export const formatEndpointsBySiteid = endpoints => {
  let objectEndpoint = {};
  endpoints.forEach(elem => {
    if (elem.aggregatedBy === "siteId" && elem.endpointType !== "saas-dc") {
      objectEndpoint = {
        ...objectEndpoint,
        ...{
          [elem.siteId]: {
            endpointId: elem.endpointId,
            symEndpointsArray: elem.metadata.selectedEndpointIds
          }
        }
      };
    }
  });
  return objectEndpoint;
};

export const arrayToObject = (array, attr) =>
  array.reduce((obj, item) => {
    obj[item[attr]] = item;
    return obj;
  }, {});

export const formatRecommedationMessage = (from, to, separator) => {
  return {
    from: `Switch from ${from.replaceAll(separator, ", ")}`,
    to: ` to ${to.replaceAll(separator, ",")}`
  };
};

export const filterByView = (view, filteredList) => {
  // Sort list by views (i.e. filter by sites, or by dates, or by app summary order, or by gains views)
  if (view == "viewBySites") {
    filteredList.sort((a, b) => b.siteId - a.siteId);
  } else if (view == "viewByGain") {
    filteredList.sort(
      (a, b) =>
        b.gains.toString().replace("%", "") -
        a.gains.toString().replace("%", "")
    );
  } else if (view == "viewByDate") {
    filteredList.sort((a, b) =>
      a.date < b.date ? -1 : a.date > b.date ? 1 : 0
    );
  } else {
    let officeActionArray = [],
      googleActionArray = [],
      voiceActionArray = [],
      salesActionArray = [],
      webexActionArray = [],
      gotoActionArray = [],
      preferedActionArray = [],
      others = [];
    const appClasses = JSON.parse(getLocalStorageFlag("activeAppClasses"));
    const preferedActionArrayList = [];

    for (const app of Object.keys(appClasses)) {
      if (!uniquelyOrderedAppArray.includes(app)) {
        preferedActionArrayList.push(app);
        break;
      }
    }

    filteredList.forEach(list => {
      if (preferedActionArrayList.includes(list.appClass)) {
        preferedActionArray.push(list);
      } else if (list.appClass === APPCLASS.OFFICE365) {
        officeActionArray.push(list);
      } else if (list.appClass === APPCLASS.WEBEX) {
        webexActionArray.push(list);
      } else if (list.appClass === APPCLASS.GOOGLE) {
        googleActionArray.push(list);
      } else if (list.appClass === APPCLASS.VOICE) {
        voiceActionArray.push(list);
      } else if (list.appClass === APPCLASS.SALESFORCE) {
        salesActionArray.push(list);
      } else if (list.appClass === APPCLASS.GOTOMEETING) {
        gotoActionArray.push(list);
      } else {
        others.push(list);
      }
    });
    filteredList = [
      ...preferedActionArray,
      ...officeActionArray,
      ...webexActionArray,
      ...googleActionArray,
      ...voiceActionArray,
      ...salesActionArray,
      ...gotoActionArray,
      ...others
    ];
  }

  return filteredList;
};

export const filterByAppGroup = (searchedActions, appGroup, selectedSite) => {
  let filteredApps = [];
  if (appGroup === "all-apps-group" && selectedSite === -1) {
    filteredApps = searchedActions;
  } else if (appGroup === "all-apps-group" && selectedSite > 0) {
    // all apps group and selected sites filter is selected
    filteredApps = searchedActions.filter(
      action => action.siteId == selectedSite.toString()
    );
  } else if (selectedSite === -1) {
    //an app group like office365 and all sites filter is selected
    filteredApps = searchedActions.filter(
      action => action.appClass === appGroup
    );
  } else {
    filteredApps = searchedActions.filter(
      action =>
        action.appClass === appGroup &&
        action.siteId === selectedSite.toString()
    );
  }

  return filteredApps;
};

export const isApplyRevertRecommendation = action => {
  return (
    action.isActive ||
    action.curState === ACTIONSTATE.APPLIED ||
    action.curState === ACTIONSTATE.REVERT_REQUEST
  );
};

export const formatGains = gain => {
  return gain.includes("-") ? (
    <span>
      <TrendDown size={15} color={"red"} />
      <span className="card-action-view-row-4">{`${gain}%`}</span>
    </span>
  ) : _.isEmpty(gain) ? (
    defaultTextValue
  ) : (
    <span>
      <HbrIcon name="trend" sentiment="success"></HbrIcon>
      <span className="card-action-view-row-4">{`${gain}%`}</span>
    </span>
  );
};

export const getTipBandwidth = () => {
  return `
    <table class=table-top>
      <tr>
        <td>{totalValueToBits}</td>
        <td>${i18nMessageBundle.commonUtilCurrentUsage}</td>
      </tr>
    </table>
    <div class=table-divider></div>
    <table class=table-bottom>
      <tr>
        <td>${i18nMessageBundle.commonUtilDate}</td>
        <td>{fromDate}</td>
      </tr>
    </table>`;
};

export const getTipBandwidthForecastLine = () => {
  return `
    <table class=table-top>
    <tr>
      <td>{forecastedValueToBits} </td>
      <td>${i18nMessageBundle.commonUtilForecastedUsage}</td>
    </tr>
    <tr>
      <td>{minToBits} </td>
      <td>${i18nMessageBundle.commonUtilMinForecastedUsage}</td>
    </tr>
    <tr>
      <td>{maxToBits} </td>
      <td>${i18nMessageBundle.commonUtilMaxForecastedUsage}</td>
    </tr>
    </table>
    <div class=table-divider></div>
    <table class=table-bottom>
    <tr>
      <td>${i18nMessageBundle.commonUtilDate}</td>
      <td>{fromDate}</td>
    </tr>
    </table>`;
};

/**
 * check if cookie exists and has a value
 */
export const checkCookieValidity = wantedCookie => {
  const cookieValue = getCookie(wantedCookie);
  let hasCookieValue = false;

  //check if cookie exists
  if (document.cookie.indexOf(`${wantedCookie}=`) === -1) {
    return false;
  }

  //check if the cookie value is an url link
  if (cookieValue.toString().includes("/")) {
    hasCookieValue = true;
  }

  return hasCookieValue;
};
