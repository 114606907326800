import { formatNumberNDecimalPlaces } from "./format";
export const percentRange = { min: 0, max: 100 };

// Get change percentage between current and previous value
export const getChangePercent = (value, change) => {
  const previous = value - change;
  const abs = value - previous;
  const avg = (value + previous) / 2;
  return Math.trunc((abs / avg) * 100);
};

// Map number from one range to another range
export const mapToRange = (inRange, outRange, value) => {
  return (
    ((value - inRange.min) * (outRange.max - outRange.min)) /
      (inRange.max - inRange.min) +
    outRange.min
  );
};

// Get range of current and previous values (used to map overlay data)
export const getOverlayRange = data => {
  const rangeData = [];

  data &&
    data.usage &&
    data.usage.forEach(d => {
      const curr = d["usage"];
      const prev = d["previous_usage"];
      rangeData.push(curr);
      rangeData.push(prev);
    });

  return { min: 0, max: Math.max(...rangeData) };
};

// Get formatted overlay data (total value optional)
export const getOverlayData = (curr, prev, totl) => {
  const current = { value: curr };
  const previous = { value: prev };
  const total = { value: totl };

  return totl
    ? Object.assign({}, { current, previous, total })
    : Object.assign({}, { current, previous });
};

// Get sum of distribution values
export const getDistributionTotal = vals => {
  const x = Object.values(vals).reduce((a, b) => Number(a) + Number(b));

  return x;
};

export const getAppQoeChange = value => {
  return Math.round(value) === 0 ? 0 : formatNumberNDecimalPlaces(value);
};
export const getAppUsageChanges = value => {
  return value > 0
    ? `+${formatNumberNDecimalPlaces(value)}%`
    : `${formatNumberNDecimalPlaces(value)}%`;
};
