import axios from "axios";
import { getCookie, deleteCookies } from "./serviceWorker";
import React from "react";
import ReactDOM from "react-dom";
import ErrorComponent from "./common/ErrorComponent";
import { BrowserRouter } from "react-router-dom";
import AppHeader from "./generics/AppHeader";
import { removeRumUser } from "./monitoring";
import storeKey from "./generics/AppHOC/reducer";
import { BASE_URL, BASE_API_URL, API_VERSION } from "./apis/apiConstants";
import { getLocalStorageFlag } from "./utils/common";

axios.interceptors.request.use(
  request => {
    // intercept request with auth headers
    if (getLocalStorageFlag("currentOverlay")) {
      request.headers["SDWAN-Overlay"] = getLocalStorageFlag(
        "currentOverlay"
      );
    }
    if (!request.url.includes("/analytics/")) {
      //set cookies only for vMange apis
      if (getLocalStorageFlag("xsrfToken")) {
        request.headers["X-XSRF-TOKEN"] = getLocalStorageFlag(
          "xsrfToken"
        );
      }
      if (getLocalStorageFlag("Vsessionid")) {
        request.headers["Vsessionid"] = getLocalStorageFlag(
          "Vsessionid"
        );
      }
    }


    if (getCookie("okta-oauth-state")) {
      request.headers["X-CSRFToken"] = getCookie("okta-oauth-state");
    }
    return request;
  },
  error => {
    // handle error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    if (
      response &&
      response.status === 200 &&
      response.data.loginState === false
    ) {
      const logoutCookie = getCookie("lg-vManageURI");
      deleteCookies();
      sessionStorage.removeItem("overlays");
      if (logoutCookie) {
        window.location.href = logoutCookie;
      }
    }

    return response;
  },
  error => {
    // handle response error
    const logoutCookie = getCookie("lg-vManageURI");
    if (
      error.response?.status === 401 &&
      error.response?.data?.User === "Logged out"
    ) {
      localStorage.removeItem("currentOverlay");
      localStorage.removeItem("currentOverlayName");
      localStorage.removeItem("okta-shared-transaction-storage");
      sessionStorage.removeItem("overlays");
      removeRumUser();
      deleteCookies();
      let globalLgCookie = storeKey.store.getState().vanalytics.app.globalFilter
        .ssoLogout;
      if (logoutCookie) {
        window.location.href = logoutCookie;
      } else if (globalLgCookie) {
        window.location.href = globalLgCookie;
      } else {
        window.location.href = `${BASE_URL}/login`;
      }
    }

    let errorPath = error.response.config.url;
    if (
      errorPath === `${BASE_API_URL}logout` ||
      errorPath === `${BASE_API_URL}csrf` ||
      errorPath === `${BASE_API_URL}metaservice/smartAccount`
    ) {
      ReactDOM.render(
        <BrowserRouter>
          <div>
            <div>
              <AppHeader
                urlPrefix={`/${API_VERSION}`}
                dashboardConfig={[]}
                errorHeader={errorPath}
              />
            </div>
            <div id="fullPageErrors">
              <ErrorComponent
                errorCode={error.response.status}
                className={"large-dashlet-error"}
                imgClassName={"error-img-styling"}
                message={
                  errorPath === `${BASE_API_URL}logout`
                    ? error.response.statusText
                    : error.response.data.error
                }
              />
            </div>
          </div>
        </BrowserRouter>,
        document.getElementById("root")
      );
    }
    return Promise.reject(error);
  }
);
