import _ from "lodash";
import { formatBytes, formatNumberNDecimalPlaces } from "../format";
import { colors, statusColors } from "../../utils/colors"
import { getFormattedDateFromString } from "../displayTime";
import { defaultTextValue } from "../enums";

export const getTrendChartConfig = title => ({
  marginTop: 40,
  pathToImages: "http://cdn.amcharts.com/lib/3/images/",
  allLabels: [
    {
      text: title,
      x: 16,
      y: -2,
      size: 16,
      width: "100%",
      align: "left"
    }
  ]
});
const getTooltipContent = (e, labels, colors, isFormatConvertedToBytes, appData) => (`
      <div class="trend-analysis-tooltip-value">
        ${getFormattedDateFromString(e.dataContext.date)}
        <br/>
        ${labels.map((label, index) => `
          <div>
            <span 
              class="${colors[index] !== undefined ? "bar-legend-common" : null}"
              style="background-color: ${colors[index]};"
            >
            </span>
            <span class="trend-analysis-tooltip-label">${label}</span> :
            <span class="trend-analysis-tooltip-value">
            ${isFormatConvertedToBytes
    ? Number.isFinite(e.dataContext.values[index])
      ? formatBytes(e.dataContext.values[index])
      : defaultTextValue
    : _.isEmpty(appData)
      ? Number.isFinite(e.dataContext.values[index])
        ? formatNumberNDecimalPlaces(e.dataContext.values[index])
        : defaultTextValue
      : index === 0
        ? appData[e.dataContext.id].good_count
        : index === 1
          ? appData[e.dataContext.id].fair_count
          : index === 2
            ? appData[e.dataContext.id].bad_count
            : 0
  }
            </span>
          </div>
        `).join(" ")}
      </div>
    `)

export const getballoonFunctionContent = (
  labels,
  colors,
  isFormatConvertedToBytes = false,
  appData = {}
) => [
    {
      balloonFunction: e => getTooltipContent(e, labels, colors, isFormatConvertedToBytes, appData)
    },
    {
      balloonFunction: e => e.dataContext.value1 === null ? getTooltipContent(e, labels, colors, isFormatConvertedToBytes, appData) : null
    },
    {
      balloonFunction: e => e.dataContext.value1 === null && e.dataContext.value2 === null ? getTooltipContent(e, labels, colors, isFormatConvertedToBytes, appData) : null
    },
    {
      balloonFunction: e => e.dataContext.value1 === null && e.dataContext.value2 === null && e.dataContext.value3 === null ? getTooltipContent(e, labels, colors, isFormatConvertedToBytes, appData) : null
    },
    {
      balloonFunction: e => e.dataContext.value1 === null && e.dataContext.value2 === null && e.dataContext.value3 === null && e.dataContext.value4 === null ? getTooltipContent(e, labels, colors, isFormatConvertedToBytes, appData) : null
    }
  ];
export const getBarChartballoonFunctionContent = (lables, colors) => [
  { showBalloon: false },
  { showBalloon: false },
  { showBalloon: false },
  {
    balloonFunction: e =>
      lables
        .map((label, i) => {
          const tooltipLabel = label == "unknown" ? "Unknown (No QoE)" : label;
          return `<div><span class="tooltip-circle" style="background-color: ${
            colors[i]
            }"></span>${tooltipLabel}: ${e.dataContext[`value${i + 1}`]}</div>`;
        })
        .join(" ")
  }
];

export const getTreeColor = vqoe => {
  switch (true) {
    case vqoe > 9.5 && vqoe <= 10:
      return statusColors.good;
    case vqoe > 9.0 && vqoe <= 9.5:
      return colors.green70;
    case vqoe >= 8.0 && vqoe <= 9.0:
      return colors.green85;
    case vqoe > 7.5 && vqoe < 8:
      return statusColors.fair;
    case vqoe > 6 && vqoe <= 7.5:
      return colors.yellow70;
    case vqoe >= 5 && vqoe <= 6:
      return colors.yellow85;
    case vqoe > 4 && vqoe < 5:
      return colors.red70;
    case vqoe > 2 && vqoe <= 4:
      return colors.red60;
    case vqoe >= 0 && vqoe <= 2:
      return statusColors.poor;
    case vqoe == "-":
      return statusColors.gray;
  }
};

export const getApplicationsTableList = (application_table_data) => {
  if (
    application_table_data &&
    application_table_data.data &&
    application_table_data.data.length > 0
  ) {
    let total = 0;
    application_table_data.data.forEach(data => {
      total += data["usage"];
      if (data.vqoe_status == "unknown") {
        data.jitter = "-";
        data.latency = "-";
        data.vqoe_score = "-";
        data.packet_loss = "-";
        data.vqoe_change = "-";
      }
      if (!Object.prototype.hasOwnProperty.call(data, "application_class")) {
        data["application_class"] = "-";
      }
    });
    application_table_data.data["top_apps_usage"] = total;
    return application_table_data.data;
  } else {
    return [];
  }
};
