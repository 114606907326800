import { FcGoogle } from "react-icons/fc";
import { FaSalesforce } from "react-icons/fa";
import { SiMicrosoftoffice, SiGotomeeting } from "react-icons/si";
import { MdOutlineKeyboardVoice } from "react-icons/md";
import { BiCategory } from "react-icons/bi";
import imgWebx from "../assets/webex-logo.svg";

export const getIcon = (iconName, font = "3.0em") => {
  switch (true) {
    case iconName === "voice":
      return (
        <MdOutlineKeyboardVoice
          style={{ color: "var(--hbr-color-neutral-border, #889099)", fontSize: font }}
        />
      );
    case iconName === "google-workspace":
      return <FcGoogle style={{ fontSize: font }} />;
    case iconName === "office365":
      return (
        <SiMicrosoftoffice
          style={{ color: "var(--hbr-color-danger, #CC2D37)", fontSize: font }}
        />
      );
    case iconName === "salesforce":
      return (
        <FaSalesforce
          style={{
            color: "var(--hbr-color-interact, #1d69cc)",
            fontSize: font
          }}
        />
      );
    case iconName === "webex":
      return <div className="webex-icon"><img src={imgWebx} alt={"Webex"} /></div>;
    case iconName === "gotomeeting":
      return (
        <SiGotomeeting
          style={{ color: "var(--hbr-color-neutral-border, #889099)", fontSize: font }}
        />
      );
    default:
      return (
        <BiCategory
          style={{
            color: "var(--hbr-color-interact, #1d69cc)",
            fontSize: font
          }}
        />
      );
  }
};
