import { defaultCellValue } from "./enums";
const byteUnits = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

// Format string (truncate if exceeds maxLength for now)
export const formatString = (str, maxLength = 50) => {
  return str && str.length > maxLength
    ? str.substr(0, maxLength - 1) + "..."
    : str;
};

// Format number using value, format and precision
export const formatNumber = (value, format = "default", decimal = 0) => {
  let formattedNumber;
  if (format === "changePercent" && value < 0) {
    formattedNumber = Math.round(value);
  } else {
    formattedNumber = Number(value).toFixed(decimal);
  }
  // Remove negative sign if value is -0
  if (formattedNumber == 0) formattedNumber = 0;
  const sign = formattedNumber > 0 ? "+" : "";

  switch (format) {
    case "percent":
      return `${formattedNumber}%`;
    case "changePercent":
      return `${sign}${formattedNumber}%`;
    case "changeValue":
      return `${sign}${formattedNumber}`;
    case "devices":
      return `/${formattedNumber}`;
    case "percentNoSign":
      return `${Math.abs(formattedNumber)}%`;
    default:
      return `${formattedNumber}`;
  }
};

// Format bits per seconds using value, format and precision
export const formatBits = fileSizeInBits => {
  var i = -1;
  var bitUnits = [
    "Kbps",
    "Mbps",
    "Gbps",
    "Tbps",
    "Pbps",
    "Ebps",
    "Zbps",
    "Ybps"
  ];
  do {
    fileSizeInBits = fileSizeInBits / 1024;
    i++;
  } while (fileSizeInBits > 1024);
  if (fileSizeInBits === 0) return 0 + " " + bitUnits[i];
  return Math.max(fileSizeInBits, 0.1).toFixed(1) + " " + bitUnits[i];
};

// Format bytes using value, format and precision
export const formatBytes = (value, format, decimals = 0, splitData = false) => {
  if (value === null || value === undefined) {
    return defaultCellValue;
  }
  if (format) {
    const size = byteUnits.findIndex(s => s === format);
    const formattedBytes = size
      ? parseFloat(value / Math.pow(10, 3 * size))
      : value;
    return formattedBytes.toFixed(decimals);
  } else {
    // choose the format based on value
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const unitIndex =
      value === 0 ? 0 : Math.floor(Math.log(Math.abs(value)) / Math.log(k));
    if (splitData) {
      return {
        value: parseFloat((value / Math.pow(k, unitIndex)).toFixed(dm)),
        size: byteUnits[unitIndex]
      };
    } else {
      return (
        parseFloat((value / Math.pow(k, unitIndex)).toFixed(dm))
        + " " + byteUnits[unitIndex]
      );
    }
  }
};

/**
 * Description: Adds rounded string or number values in bytes unit
 * @param {*} values are the string values to be added
 * @param {*} unit is custom unit from byteUnits array
 * @param {*} decimals is the number of decimal positions after dot
 * @param {number} mode is the expected return value ( 0 - formatted, 1 - number, 2 - both)
 */

export const addBytes = (values, unit, split) => {
  let total = 0;
  let result = {
    values: values.map(value => {
      const convertedValue = {
        value: 0,
        num: 0,
        unit: byteUnits[0],
        str: `0 ${byteUnits[0]}`
      };
      let str = value;
      if (Number.isFinite(value)) str = formatBytes(value, unit);

      if (typeof str === "string") {
        const [num, strUnit] = str.split(" ");
        const unitIndex = byteUnits.indexOf(strUnit);
        if (unitIndex !== -1) {
          convertedValue.num = num;
          convertedValue.unit = strUnit;
          convertedValue.str = str;
          convertedValue.value = num * Math.pow(2, 10 * unitIndex);
          total += convertedValue.value;
        }
      }
      return convertedValue;
    })
  };
  result.value = total;
  result.str = formatBytes(total, unit);

  if (split) {
    const [num, unit] = result.str.split(" ");
    result.num = num;
    result.unit = unit;
  }

  return result;
};

// Convert millis to date format for x-axis of chart
export const formatChartDate = millis => {
  const date = new Date(parseInt(millis));
  return `${formatDate(date)} ${formatTime(date)}`;
};

// Return formatted date string (mm/dd/yyyy)
const formatDate = date => {
  const mm = formatDigits(date.getMonth() + 1);
  const dd = formatDigits(date.getDate());
  const yyyy = date.getFullYear();
  return `${mm}/${dd}/${yyyy}`;
};

// Return formatted time value (hh:mm)
const formatTime = date => {
  const hh = formatDigits(date.getHours());
  const mm = formatDigits(date.getMinutes());
  return `${hh}:${mm}`;
};

// Return digits as a string (pad with 0 if under 10)
const formatDigits = time => {
  return time < 10 ? `0${time}` : `${time}`;
};

export const formatNumberNDecimalPlaces = (num, decimals = 1) => {
  const dm = decimals < 0 ? 0 : decimals;
  if (num === null || num === undefined || typeof num !== "number") {
    return "-";
  } else {
    // check if num is int or float
    const isInt = Number.isInteger(num);
    if (isInt) return num;
    const result = parseFloat("" + Math.round(num * 100) / 100).toFixed(dm);
    return parseFloat(result).toString();
  }
};

export const capitalizeString = str => {
  if (!str || typeof str !== "string") {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatNumberToPositiveInteger = value => {
  let formattedNumber = Number(value).toFixed(0);
  return Math.abs(formattedNumber);
};

export const formatSizeUnits = (bytes, hasFixBytes) => {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(1) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(1) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(1) + " KB";
  } else if (bytes > 1) {
    bytes = (hasFixBytes ? bytes.toFixed(1) : bytes) + " bytes";
  } else if (bytes == 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
};

export const formatSizeUnitsRound = (bytes, hasFixBytes) => {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed() + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed() + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed() + " KB";
  } else if (bytes > 1) {
    bytes = (hasFixBytes ? bytes.toFixed() : bytes) + " bytes";
  } else if (bytes === 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
};

// Format usage volume in Site Dashboard Table
export const formatSiteTableBytes = (value, decimals = 1) => {
  if (value === null || value === undefined) {
    return defaultCellValue;
  }
  else {
    // choose the format based on value
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const i =
      value == 0 ? 0 : Math.floor(Math.log(Math.abs(value)) / Math.log(k));
    return (
      parseFloat((value / Math.pow(k, i)).toFixed(dm)) + " " + byteUnits[i]
    );
  }
};

