// update filters into simple and aggregate query request objects
// support for v2 common queries
// filters format :
// {
//   application : ['abc'],
//   entry_time: ['','']
// }
import { regexDomains } from "../utils/enums";
import _ from "lodash";
export const formatReq = (reqConfig, filters) => {
  const newReqConfig = Object.assign({}, {}, reqConfig);
  // update filters inside query
  if (Object.prototype.hasOwnProperty.call(newReqConfig, "query")) {
    const { rules } = newReqConfig.query;
    Object.entries(filters).map(f => {
      let rule = rules.find(r => r.field === f[0]);
      if (rule) {
        // update
        if (f[1] !== undefined) {
          rule = Object.assign(rule, {
            value: f[1]
          });
        } else {
          newReqConfig.query.rules.forEach(rule => {
            if (Array.isArray(rule.value) && rule.value.length === 0) {
              const index = newReqConfig.query.rules.indexOf(rule);
              if (index > -1) {
                newReqConfig.query.rules.splice(index, 1);
              }
            }
          });
        }
      } else {
        // add
        rules.push({
          field: f[0],
          operator: "in",
          value: f[1]
        });
      }
    });

    // TODO add support for additional filters later
  }

  return newReqConfig;
};

// form O365 sort/filter query from aoData
export const formatRequestPayload = (
  requestConfig,
  aoData,
  tableRef,
  columnGroup
) => {
  let newRequestConfig = Object.assign({}, requestConfig);
  const sort = {};
  const search = {};

  //Fetch data from aoData
  if (tableRef) {
    const searchQuery = tableRef.getSearchParams(aoData).search_queries;

    // Get and Set filter data
    if (Object.keys(searchQuery).length > 0) {
      Object.keys(searchQuery).reduce((filter, val) => {
        search[val] = [searchQuery[val]];
      }, {});
      newRequestConfig = formatReq(newRequestConfig, search);
    }

    // Get and Set sort data
    const sortedData = tableRef.getQueryObj(aoData);
    const direction = sortedData.sortDir === "des" ? "desc" : "asc";
    sort.property = sortedData.sortCol;
    sort.order = direction;

    // Additional check for Path score data grouping
    if (sortedData.sortCol === columnGroup && requestConfig.sort.length > 0) {
      newRequestConfig.sort = requestConfig.sort;
      newRequestConfig.sort = newRequestConfig.sort.map(so => {
        let sortOrder = {};
        if (so.property === columnGroup) {
          sortOrder.order = direction;
          sortOrder.property = sortedData.sortCol;
        } else {
          sortOrder.order = so.order;
          sortOrder.property = so.property;
        }
        return sortOrder;
      });
    } else {
      newRequestConfig.sort = new Array(sort);
    }

    // Get and set size/offset - pagination parameters
    newRequestConfig.size = sortedData.pageLength;
    newRequestConfig.offset = sortedData.start - 1;
  }

  return newRequestConfig;
};

// Form Pagination/Sorting/Filtering query from aoData except O365
export const createRequest = (requestConfig, aoData, tableRef) => {
  let newRequestConfig = Object.assign({}, requestConfig);

  //Fetch data from aoData
  if (tableRef) {
    const searchQuery = tableRef.getSearchParams(aoData).search_queries;

    // Get and Set filter data
    if (Object.keys(searchQuery).length > 0) {
      const columnFilter = {};
      Object.keys(searchQuery).map(key => {
        if (searchQuery[key] !== undefined && searchQuery[key] !== "") {
          columnFilter[key] = [searchQuery[key]];
        }
      });
      newRequestConfig.table_filter = columnFilter;
    }

    // Get and Set sort data
    const sortedData = tableRef.getQueryObj(aoData);
    const direction = sortedData.sortDir === "des" ? "desc" : "asc";
    
    newRequestConfig.sort = {
      [sortedData.sortCol]: direction
    };

    //Get and set size/offset
    newRequestConfig.size = sortedData.pageLength;
    newRequestConfig.offset = sortedData.start - 1;
  }

  return newRequestConfig;
};

// prepareQuery for time filter
export const prepareQuery = (request, filter) => {
  const defaultTime = 12;
  const toDaysConverter = 24;
  let requestConfig = Object.assign({}, request);
  const timeSelected = parseInt(filter.timePeriodSelected);
  // set global time filter
  if (Object.prototype.hasOwnProperty.call(requestConfig, "query")) {
    const { rules } = requestConfig.query;
    let rule = rules.find(r => r.field === "entry_time");
    if (!timeSelected) {
      rule.value = filter.timeFilter.current_period;
      rule.operator = "between";
    } else if (timeSelected > defaultTime) {
      const timeFormat = (timeSelected / toDaysConverter).toString();
      rule.value = new Array(timeFormat);
      rule.operator = "last_n_days";
    } else {
      rule.value = new Array(filter.timePeriodSelected);
      rule.operator = "last_n_hours";
    }
  }

  return requestConfig;
};

// Accounts View - OIDC and SAML utilities
export const verifyDomainIdentifiers = value => {
  const reg = new RegExp(regexDomains);
  const filteredData = value.filter(val => !reg.test(val));
  if (filteredData.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const validateIdpFields = state => {
  const formValidations = {};
  Object.keys(state).map(key => {
    if (
      (state[key] === "" || state[key].length === 0)
    ) {
      formValidations[key] = true;
    }
  });
  return formValidations;
};

export const formatDomainIdentifiers = data => {
  const formattedData = data.map(di => {
    return {
      text: di,
      value: di
    };
  });
  return formattedData;
};

export const compareObjects = (state, stateRepl) => {
  return _.isEqual(state, stateRepl) ? true : false;
};

export const getDefaultTimeFilter = payload => {
  const curr = payload.current_period
    ? payload.current_period
    : payload.time_period.current_period;
  const prev = payload.previous_period
    ? payload.previous_period
    : payload.time_period.previous_period;

  return {
    current_period: curr,
    previous_period: prev
  };
};