import customOktaConfig from "../config/okta-config";
import ciscoLogo from "../assets/Cisco_Logo_no_TM_Cisco_Blue-RGB.svg";
const oktaConfig = customOktaConfig.getRegionSpecifics(true);
let baseUrl = oktaConfig.baseUrl;
let clientId = oktaConfig.clientId;
let redirectUri = oktaConfig.redirectUri;
let issuer = oktaConfig.issuer;
let responseType = oktaConfig.responseType;
let pkce = oktaConfig.pkce;
let display = oktaConfig.display;
let scopes = oktaConfig.scopes;
let nonce = oktaConfig.nonce;
let title = oktaConfig.title;
let font = oktaConfig.font;
let idp = oktaConfig.idp;

export const oktanonIdpConfig = {
  baseUrl: baseUrl,
  clientId: clientId,
  redirectUri: redirectUri,
  logo: ciscoLogo,
  authParams: {
    issuer: issuer,
    responseType: responseType,
    pkce: pkce,
    display: display,
    scopes: scopes,
    nonce: nonce
  },
  i18n: {
    en: title,
    fr: font
  },
  features: {
    idpDiscovery: false
  }
};

export const oktaIdpConfig = {
  baseUrl: baseUrl,
  clientId: clientId,
  redirectUri: redirectUri,
  logo: ciscoLogo,
  authParams: {
    issuer: issuer,
    responseType: responseType,
    pkce: pkce,
    display: display,
    scopes: scopes,
    nonce: nonce
  },
  i18n: {
    en: title,
    fr: font
  },
  features: {
    idpDiscovery: true
  },
  idps: [{ type: "cisco", text: "Sign In with Cisco CCO ID", id: idp }],
  idpDisplay: "SECONDARY"
};
