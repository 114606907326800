import moment from "moment";
import {
  subMinutes,
  startOfDay,
  lightFormat,
  format,
  parse
} from "date-fns";
import { getTimeFilter, convertHoursToFreq } from "./common";
import { REPEATS } from "./enums";

export const getCurrentTimeStamp = () => {
  let currentDateTime = new Date();

  // Extract year, month, day, hour, minute, and second components
  let year = currentDateTime.getFullYear();
  let month = String(currentDateTime.getMonth() + 1).padStart(2, '0');
  let day = String(currentDateTime.getDate()).padStart(2, '0');
  let hour = String(currentDateTime.getHours()).padStart(2, '0');
  let minute = String(currentDateTime.getMinutes()).padStart(2, '0');
  let second = String(currentDateTime.getSeconds()).padStart(2, '0');
  let ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12;
  hour = hour ? hour : 12; // Handle midnight (0 hours) as 12 AM

  return `${year}-${month}-${day} ${hour}${minute}${second} ${ampm}`;
};

export const getStartOfMinute = () => {
  return moment().startOf("minute");
};

export const getStartOfHour = (input = new Date()) => {
  return moment(input).startOf("hour");
};

export const getStartOfDay = (input = new Date()) => {
  return moment(input).startOf("day");
};

export const getTodayEndOfDay = (input = new Date()) => {
  return moment(input)
    .endOf("day")
    .startOf("minute");
};

export const formatDate = input => {
  return moment(input);
};

export const displayPathAnalyticsTime = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("MM/DD/YYYY, h:mm:ss A");
  }
  return localTime;
};

export const displayPathScoreTime = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("DD MMM yy h:mm A");
  }
  return localTime;
};

export const displayUserExpChartTooltp = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("DD MMM hh A");
  }
  return localTime;
};

export const shortDisplayTime = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("MMM DD, YYYY hh:mm A");
  }
  return localTime;
};

export const shortDisplayTimeOnly = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("hh:mm A");
  }
  return localTime;
};

export const shortDisplayTimeCharts = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("h:mm a");
  }
  return localTime;
};

export const shortDisplayDate = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("DD MMM yy");
  }
  return localTime;
};

export const shortDisplayDateFullYear = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("MMM DD, YYYY");
  }
  return localTime;
};

export const shortDisplayDateYearFirst = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("YYYY-MM-DD");
  }
  return localTime;
};

export const shortDisplayDate24Hour = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("YYYY-MM-DD HH:mm");
  }
  return localTime;
};
export const shortDisplayDate24HourWithSec = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("YYYY-MM-DD HH:mm:ss");
  }
  return localTime;
};

export const shortDisplayDate24HourWithSecUTC = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).utc().format("YYYY-MM-DD HH:mm:ss");
  }
  return localTime;
};
export const getHourFromDate = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("h");
  }
  return localTime;
};

export const displayFullMonth = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("MMMM");
  }
  return localTime;
};

export const isDateValid = input => {
  return moment(input).isValid();
};

export const updateCurrTime = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).valueOf();
  }
  return localTime;
};

export const updateCurrTimeMinute = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input)
      .startOf("minute")
      .valueOf();
  }
  return localTime;
};

export const nextDay = updatedTime => {
  let currTime = moment(updatedTime).startOf("minute");

  return moment(currTime)
    .add(1, "days")
    .valueOf();
};

//i.e. startType="days"
//i.e. diff = 24 * 60 + 1
//i.e. diffType = "days"
export const getPeriod = (time, startType, diff, diffType) => {
  return moment(time)
    .subtract(diff, diffType)
    .startOf(startType).utc()
    .local()
    .valueOf();
};

export const getTimeRepeats = (repeats, currTime) => {
  let nextRun = "";

  if (REPEATS.DAILY === repeats) {
    nextRun = moment(currTime)
      .add(1, "days")
      .valueOf();
  } else if (REPEATS.WEEKLY === repeats) {
    nextRun = moment(currTime)
      .add(1, "weeks")
      .valueOf();
  } else {
    nextRun = moment(currTime)
      .add(30, "days")
      .valueOf();
  }
  return nextRun;
};

export const getRange = time => {
  const hours = moment(time.current_period_end).diff(
    moment(time.current_period_start),
    "hours"
  );
  return convertHoursToFreq(hours);
};

export const getDiffBtwDates = (toDate, fromDate, type = "hours") => {
  return moment(toDate).diff(moment(fromDate), type);
};

export const shortDisplayDateTime = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("MM/DD/YY h A");
  }
  return localTime;
};

export const shortDisplayDateTimeHour = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("MM/DD/YY h:mm A");
  }
  return localTime;
};

export const updateTimeWithOffset = (
  createdBy,
  currPeriod,
  isDaySavingTime = true
) => {
  const _createdBy = createdBy === "ui" ? "ui_0_0" : createdBy; //i.e._createdBy could be 'ui_420' or 'ui_-330'
  const offsetStart = parseInt(_createdBy.split("_")[1]);
  const offsetEnd = parseInt(_createdBy.split("_")[2]);
  const offsetDifference = offsetEnd - offsetStart;
  const positiveOffset = offsetDifference > 0 ? 3600 * 1000 : -3600 * 1000; //offset is 420
  const negativeOffset = offsetDifference < 0 ? +3600 * 1000 : -3600 * 1000; //offset is -120

  if (isDaySavingTime || offsetDifference === 0) {
    return new Date(
      offsetStart > 0
        ? currPeriod - offsetStart * 60 * 1000
        : currPeriod + Math.abs(offsetStart) * 60 * 1000
    ).toString();
  }

  return new Date(
    offsetStart > 0
      ? currPeriod - offsetStart * 60 * 1000 - positiveOffset
      : currPeriod + Math.abs(offsetStart) * 60 * 1000 + negativeOffset //i.e 1647212400000 + 120*60*1000+3600000
  ).toString();
};

export const getOffset = time => {
  return moment(time).utcOffset() / 60;
};

export const isDateInBetween = (compareDate, fromDate, toDate) => {
  return moment(compareDate.valueOf()).isBetween(fromDate, toDate, "time");
};

export const getRangeFourHours = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).subtract(4, "h");
  }
  return localTime;
};

export const getRangeNextFourHours = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).add(4, "h");
  }
  return localTime;
};

export const currentDateFormat = (input) => {
  let localTime = "";

  if (input) {
    localTime = moment(input)
  }
  return localTime;
};

export const subtractUnitsFromDate = (input, numberUnits, typeTime) => {
  let localTime = "";

  if (input) {
    localTime = moment(input).subtract(numberUnits, typeTime);
  }
  return localTime;
};

export const addUnitsFromDate = (input, numberUnits, typeTime) => {
  let localTime = "";

  if (input) {
    localTime = moment(input).add(numberUnits, typeTime);
  }
  return localTime;
};

export const getRangeDurationAsHours = (max, min) => {
  let duration = "";
  let hours = "";
  if (max && min) {
    duration = moment.duration(moment(max).diff(moment(min)));
    hours = duration.asHours();
  }
  return hours;
};

export const getArrayMinValue = input => {
  let min = "";
  if (input) {
    min = Math.min(...input);
  }
  return min;
};

export const getArrayMaxValue = input => {
  let max = "";
  if (input) {
    max = Math.max(...input);
  }
  return max;
};

export const getArrayAvgValue = input => {
  let avg = "";
  if (input) {
    avg = input.reduce((a, b) => a + b, 0) / input.length;
  }
  return avg;
};

/**
 * Description: Returns the given date as a string using given date format
 * @param {Date | number} date is a Date object / timestamp
 * @param {string} strFormat is the format of the final date string
 * @returns {string} date string in the given date string format
 */
export const displayDateTime = (date, strFormat = "MMM dd, yyyy hh:mm aa") => {
  return date ? format(date, strFormat) : "";
};

export const displayDateTimeSeconds = (date, format = "MMM DD, YYYY hh:mm:ss A") => {
  return date ? moment(date).format(format) : "";
};

export const prevDay = (input = new Date()) => {
  let now = moment(input);
  let localOffset = now.utcOffset();
  let prevDay = now.subtract(1, "days").valueOf();
  if (localOffset > 0) {
    let utcTime = moment(prevDay).utc().startOf("day").valueOf();
    return utcTime
  }
  return prevDay;
}

export const getTimePeriodELT = (time, seed) => {
  const timeFilter = getTimeFilter(time);
  let current_period = [];
  let previous_period = [];
  let inputTime = seed ? seed : new Date();
  // floor it to previous 5th min
  const minute = new Date().getMinutes();
  const fifthMinute = new Date(Math.floor(minute % 5)).getTime();
  const currTime = shortDisplayDateTimeHour(subMinutes(new Date(inputTime), fifthMinute))
  const currentValue = new Date(currTime).valueOf();

  switch (timeFilter) {
    case "1": {
      current_period = [
        moment(currTime)
          .subtract(1, "hours")
          .utc()
          .startOf("hour")
          .local()
          .valueOf(),
        currentValue,
      ];
      previous_period = [
        moment(currTime)
          .subtract(minute, "minutes")
          .subtract(1, "hour")
          .utc()
          .startOf("hour")
          .local()
          .valueOf(),
        moment(currTime)
          .subtract(2, "hours")
          .utc()
          .startOf("hour")
          .local()
          .valueOf()
      ];
      return { current_period, previous_period };
    }
    case "3": {
      current_period = [
        moment(currTime)
          .subtract(3, "hours")
          .utc()
          .startOf("hour")
          .local()
          .valueOf(),
        currentValue,
      ];
      previous_period = [
        moment(currTime)
          .subtract(6, "hours")
          .utc()
          .startOf("hour")
          .local()
          .valueOf(),
        subMinutes(new Date(currTime), 121)
          .valueOf()//181 mins=3hours*60mins+1min
      ];
      return { current_period, previous_period };
    }
    case "6": {
      current_period = [
        moment(currTime)
          .subtract(6, "hours")
          .utc()
          .startOf("hour")
          .local()
          .valueOf(),
        currentValue,
      ];
      previous_period = [
        moment(currTime)
          .subtract(12, "hours")
          .utc()
          .startOf("hour")
          .local()
          .valueOf(),
        subMinutes(new Date(currTime), 301)
          .valueOf()//361 mins=6hours*60mins+1min
      ];
      return { current_period, previous_period };
    }
    case "24": {
      current_period = [
        moment(currTime)
          .subtract(24, "hours")
          .utc()
          .startOf("hour")
          .local()
          .valueOf(),
        currentValue,
      ];
      previous_period = [
        moment(currTime)
          .subtract(48, "hours")
          .utc()
          .startOf("hour")
          .local()
          .valueOf(),
        subMinutes(new Date(currTime), 1381)
          .valueOf()//1441 mins=24hours*60mins+1min
      ];
      return { current_period, previous_period };
    }
    case "168": {
      current_period = [
        moment(currTime)
          .startOf("day")
          .subtract(7, "days")
          .utc()
          .startOf("hour")
          .local()
          .valueOf(),
        currentValue,
      ];
      previous_period = [
        moment(currTime)
          .startOf("day")
          .subtract(14, "days")
          .utc()
          .startOf("hour")
          .local()
          .valueOf(),
        subMinutes(startOfDay(new Date(currTime)), 10081)
          .valueOf()//10081 minutes=7days*24h*60min+1minute
      ];
      return { current_period, previous_period };
    }
    case "720": {
      current_period = [
        moment(currTime)
          .startOf("day")
          .subtract(30, "days")
          .utc()
          .startOf("hour")
          .local()
          .valueOf(),
        currentValue,
      ];
      previous_period = [
        moment(currTime)
          .startOf("day")
          .subtract(60, "days")
          .utc()
          .startOf("hour")
          .local()
          .valueOf(),
        subMinutes(startOfDay(new Date(currTime)), 43201)
          .valueOf(),//43201 minues=30days*24h*60mins+1minute
      ];
      return { current_period, previous_period };
    }
    default: {//case for 12 hours
      current_period = [
        moment(currTime)
          .subtract(12, "hours")
          .utc()
          .startOf("hour")
          .local()
          .valueOf(),
        currentValue,
      ];
      previous_period = [
        moment(currTime)
          .subtract(24, "hours")
          .utc()
          .startOf("hour")
          .local()
          .valueOf(),
        subMinutes(new Date(currTime), 721)
          .valueOf()//721 mins=12hours*60mins+1min
      ];
      return { current_period, previous_period };
    }
  }
};

export const getTimePeriodStartHour = (time, diff) => {
  return (
    moment(time)
      .subtract(diff, "hours")
      .utc()
      .local()
      .valueOf()
  )
}

export const getEndOfHour = (input = new Date()) => {
  return moment(input).endOf("hour");
};

export const DisplayDateMonth = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("DD-MMM");
  }
  return localTime;
};

export const getPreviousMonthDate = (input) => {
  let localTime = "";
  if (input) {
    localTime = moment().utc().subtract(input, 'd').format("MMM DD ");
  } else {
    localTime = moment().utc().format("MMM DD ");
  }

  return localTime;
};

export const getPreviousYearMonthDate = (input) => {
  let localTime = "";
  if (input) {
    localTime = moment.utc().subtract(input, 'd').format("YYYY-MM-DD 00:00:00");
  } else {
    localTime = moment.utc().format("YYYY-MM-DD 00:00:00");
  }

  return localTime;
};

export const getPreviousYearMonthDateTime = (input) => {
  const localTime = moment(input)
    .startOf("day")
    .subtract(30, "days")
    .format("YYYY-MM-DD HH:mm:ss");
  return localTime;
};

export const shortDisplayDateForSdwan = input => {
  let localTime = "";
  if (input) {
    localTime = moment(input).format("MMM DD YYYY");
  }
  return localTime;
};

export const getTimePeriodForAD = () => {
  let currTime = getStartOfHour();
  let currTimeEphoc = currTime.valueOf();
  let dateWeekAgo = getStartOfDayUTC(moment(currTimeEphoc).startOf("day").subtract(6, "days").utc().startOf("hour").local().valueOf());
  let todayDate = currTimeEphoc;
  return [dateWeekAgo, todayDate];
};

/**
 * Description: This method converts date hour number to timestamp
 * @param {*} dateHour is a date time number in YYYYMMDDHH format
 */

export const convertDateHourToTimestamp = dateHour => {
  if (dateHour !== null) {
    const dateHourStr = dateHour.toString();
    const year = dateHourStr.slice(0, 4);
    const month = parseInt(dateHourStr.slice(4, 6)) - 1;
    0, 1;
    const date = dateHourStr.slice(6, 8);
    const hour = dateHourStr.slice(8, 10);
    const min = dateHourStr.slice(10, 12);
    return Date.UTC(year, month, date, hour, min);
  }
};

/**
 * Description: This method converts date number to timestamp
 * @param {*} date is a date time number in YYYYMMDD format
 */

export const convertDateToTimestamp = dateNumber => {
  const dateStr = dateNumber.toString();
  const year = dateStr.slice(0, 4);
  const month = parseInt(dateStr.slice(4, 6)) - 1;
  0, 1;
  const date = dateStr.slice(6, 8);
  return Date.UTC(year, month, date);
};

/**
 * Description: This method returns beginning time of UTC date
 * @param {number | Date | string} startDate is the start date
 * @returns {number} beginning time of UTC date in milliseconds
 */
export const getStartOfDayUTC = startDate => {
  const date = new Date(startDate);
  return Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
};

/**
 * Description: This method ending time of UTC date
 * @param {number | Date | string} endDate is the end date
 * @returns {number} ending time of UTC date in milliseconds
 */
export const getEndOfDayUTC = endDate => {
  const date = new Date(endDate);
  return Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59,
    999
  );
};

export const getStartfDayUTCFormat = (dateStr) => {
  const utcTime = moment.utc(dateStr);
  return utcTime.format("MMM DD, YYYY hh:mm A")// Get timestamp
};

export const convertTsToLocal = (timestamp) => {
  let date = timestamp + " GMT"
  return new Date(date);
};

/**
 * Description: Returns the given date as a sring using given utc date format
 * @param {Date | number} date is a Date object / timestamp
 * @param {string} format is the format of the final date string
 * @returns utc date string in the given format
 */
export const getFormattedUTCDate = (date, format = "yyyy-MM-dd HH:mm:ss") => {
  if (date) {
    const localeDate = new Date(date);
    const timestampUTCDate =
      localeDate.getTime() + (localeDate.getTimezoneOffset() * 60 * 1000);
    return lightFormat(timestampUTCDate, format);
  } else return "";
};

/**
 * Description: Returns the timestamp of the given UTC date string using the given date format
 * @param {string} date is a UTC date string
 * @param {string} format is the format of the given UTC date string without time zone
 * @returns the timestamp of the given UTC date string
 */
export const getTimestampFromUTCString = (
  date,
  format = "yyyy-MM-dd HH:mm:ssX"
) => {
  return parse(`${date}Z`, format, new Date()).getTime();
};

/**
 * Description: Returns the given UTC date string in the new date string format
 * @param {string} date is a date string
 * @param {string} strFormat is the format of the final date string
 * @param {string} sourceFormat is the format of the given UTC date string without time zone
 * @returns {string} date string in the new date string format
 */
export const getFormattedDateFromUTCString = (
  date,
  strFormat = "MMM dd, yyyy hh:mm aa",
  sourceFormat = "yyyy-MM-dd HH:mm:ssX"
) => {
  return format(parse(`${date}Z`, sourceFormat, new Date()), strFormat);
};

/**
 * Description: Returns the given date string in the new date string format
 * @param {string} date is a date string
 * @param {string} sourceFormat is the format of the source date string
 * @param {string} strFormat is the format of the final date string
 * @returns {string} date string in the new date string format
 */
export const getFormattedDateFromString = (
  date,
  sourceFormat = "MM/dd/yyyy HH:mm",
  strFormat = "MMM dd, yyyy hh:mm aa"
) => {
  return format(parse(date, sourceFormat, new Date()), strFormat);
};
