import { am4core, am4charts } from "../loaders/amchartsLoader";
import reduxContext from "../generics/AppHOC/reducer";
import { formatBytes, formatSizeUnits } from "../utils/format";
import { colors } from "./colors";
import { addUnitsFromDate, subtractUnitsFromDate } from "../utils/displayTime";
import { startOfHour } from "date-fns";
import { byteDecimalUnits } from "./enums";
import { getTimeInterval } from "./common";

export const centerLegend = { align: "center" };

export const getCustomValueAxisConfig = (value, toValue, fillColor) => {
  return [
    {
      maximum: 10,
      guides: [
        {
          value,
          toValue,
          lineAlpha: 1,
          lineColor: colors.gray90,
          dashLength: 3,
          fillAlpha: 0.2,
          fillColor,
          inside: true
        }
      ]
    }
  ];
};

/**
 * Description: Converts y axis value into decimal byte unit text
 * reference: https://www.iec.ch/prefixes-binary-multiples
 * @param {number} - y axis value - number of bytes
 * @returns {string} y axis value in decimal byte unit
 */

export const formatYAxisDecimalBytes = value => {
  let result = "";
  if (value <= 0) result = "0";
  else {
    if (value < 1e9) {
      if (value < 1e3) result = value + byteDecimalUnits[0];
      else if (value < 1e6) result = value / 1e3 + byteDecimalUnits[1];
      else result = value / 1e6 + byteDecimalUnits[2];
    }
    else {
      if (value < 1e12) result = value / 1e9 + byteDecimalUnits[3];
      else if (value < 1e15) result = value / 1e12 + byteDecimalUnits[4];
      else if (value < 1e18) result = value / 1e15 + byteDecimalUnits[5];
      else if (value < 1e21) result = value / 1e18 + byteDecimalUnits[6];
      else if (value < 1e24) result = value / 1e21 + byteDecimalUnits[7];
      else result = value / 1e24 + byteDecimalUnits[8];
    }
  }
  return result;
};

export const selectedBWValueAxesConfig = [
  {
    autoGridCount: true,
    gridAlpha: 0.3,
    labelFunction: formatSizeUnits
  }
];

export const chartScrollbarValueAxesConfig = [
  { autoGridCount: true, gridAlpha: 0.3 }
];
export const chartUsageScrollbarValueAxesConfig = [
  {
    autoGridCount: true,
    gridAlpha: 0.3,
    //logarithmic: true,
    labelFunction: function (value, valueText) {
      valueText = value <= 0 ? "0" : formatBytes(value);
      return valueText;
    }
  }
];
// if need chart zoom update enable with true
export const chartScrollbarConfig = {
  enabled: false,
  backgroundColor: "transparent",
  selectedBackgroundColor: "transparent",
  dragIconHeight: 85,
  dragIcon: `${window.location.protocol}//${window.location.host}${process.env.PUBLIC_URL}/ciscoDragIcon`,
  offset: -83,
  scrollbarHeight: 85
};

export const chartScrollbarListeners = [
  {
    event: "init",
    method: e => {
      e.chart.chartDiv.addEventListener("click", () => { });
    }
  },
  {
    event: "rendered",
    method: e => {
      const sb = e.chart.chartScrollbar.set.node;
      sb.addEventListener("mousedown", () => {
        e.chart.mouseIsDown = true;
      });
      e.chart.chartDiv.addEventListener("mouseup", () => {
        e.chart.mouseIsDown = false;
      });
    }
  },
  {
    event: "zoomed",
    method: e => {
      e.chart.lastZoomed = e;
    }
  }
];

/**
 * Description: This method sets style to am4 chart tooltip
 * @param {object} tooltip - tooltip object.
 * @returns {void}
 */

export const customizeTooltip = (tooltip, bforecastFlag = false) => {
  // disable default tooltip style and set custom tooltip style
  tooltip.getFillFromObject = false;
  tooltip.background.fill = am4core.color(colors.gray100);
  tooltip.label.fill = am4core.color(colors.gray15);
  tooltip.label.paddingTop = 12;
  tooltip.label.paddingRight = 16;
  tooltip.label.paddingBottom = 12;
  tooltip.label.paddingLeft = 16;
  // set tooltip border style
  tooltip.background.strokeWidth = 2;
  tooltip.background.stroke = am4core.color(colors.gray45);
  tooltip.background.cornerRadius = 6;
  // set tooltip shadow style
  const shadow = tooltip.background.filters.getIndex(0);
  shadow.dy = 4;
  shadow.blur = 12;
  shadow.color = am4core.color(colors.gray0);
  shadow.opacity = 0.18;

  if (bforecastFlag) {
    tooltip.dy = -70;
    // set tooltip orientation horizontal
    tooltip.pointerOrientation = "horizontal";
  } else {
    // set tooltip orientation vertical
    tooltip.pointerOrientation = "vertical";

    // remove tooltip pointer
    tooltip.background.pointerLength = 0;
  }
};

/**
 * Description: This method sets style to am4 chart scroll bar
 * and adds the scroll bar to the given am4 chart
 * @param {object} [chart=null] - am4 chart
 * @param {boolean} [scrollBarSeries=false] - enables series in scroll bar
 * @param {object} [props=null] - enables initial zoom of chart
 * @returns {void}
 */

export const addScrollBar = (
  chart = null,
  scrollBarSeries = false,
  props = null
) => {
  // create a scroll bar
  const scrollBar = new am4charts.XYChartScrollbar();
  // enable scroll bar
  scrollBar.disabled = false;
  // set scroll bar style
  scrollBar.minHeight = 30;
  scrollBar.thumb.minWidth = (props?.id === "kpi-trend-chart" || props?.id === "kpi-Usage-trend-chart") ? 5 : 50;
  scrollBar.background.fill = am4core.color(colors.blue70);
  scrollBar.background.fillOpacity = 0.5;
  // enable and customize scroll bar grips
  scrollBar.startGrip.visible = true;
  scrollBar.endGrip.visible = true;
  scrollBar.startGrip.icon.disabled = true;
  scrollBar.startGrip.background.disabled = true;
  const startLine = scrollBar.startGrip.createChild(am4core.Rectangle);
  startLine.height = 30;
  startLine.width = 5;
  startLine.fillOpacity = 0.4;
  startLine.align = "center";
  startLine.valign = "middle";
  scrollBar.endGrip.icon.disabled = true;
  scrollBar.endGrip.background.disabled = true;
  const endLine = scrollBar.endGrip.createChild(am4core.Rectangle);
  endLine.height = 30;
  endLine.width = 5;
  endLine.fillOpacity = 0.4;
  endLine.align = "center";
  endLine.valign = "middle";

  if (chart) {
    if (scrollBarSeries === true) {
      // add graph line reference to scroll bar
      chart.series.each(series => scrollBar.series.push(series));
      // set graph line style in scroll bar
      scrollBar.scrollbarChart.series.each(
        scrollSeries => (scrollSeries.strokeDasharray = "2,4")
      );
    }
    // set scroll bar to x axis
    chart.scrollbarX = scrollBar;
    // set scroll bar parent
    chart.scrollbarX.parent = chart.bottomAxesContainer;

    // set initial zoom in x axis
    if (props?.scrollBarInitialZoom) {
      // set start and end of initial zoom in x axis
      let maxDate =
        props.data.length > 0
          ? props.data[props.data.length - 1].date
          : props.max;
      if (maxDate instanceof Date) maxDate = maxDate.getTime();
      if (maxDate > props.max) maxDate = props.max;
      const startDate = new Date(maxDate);
      const hours = Math.round(Math.abs(props.max - props.min) / 36e5) / 3;
      startDate.setHours(startDate.getHours() - hours);
      // zoom in when chart is ready
      chart.events.on("ready", () =>
        chart.xAxes.getIndex(0).zoomToDates(startDate, new Date(maxDate))
      );
      // disable zoom out button
      chart.zoomOutButton.disabled = true;
    }
    if (props?.id === "kpi-trend-chart" || props?.id === "kpi-Usage-trend-chart") {
      chart.events.on("ready", () => {
        // scrollDisplay = true;
        let endDate = new Date(addUnitsFromDate(new Date(props.selectedHour), 1, "h"));
        chart.zoomOutButton.disabled = true;
        chart.xAxes.getIndex(0).zoomToDates(
          new Date(
            subtractUnitsFromDate(endDate, 6, "h")
          ),
          new Date(endDate)
        );
        chart.xAxes.getIndex(0).keepSelection = true;
      });
    }
  }
};

/**
 * Description: This method sets style to legend
 * @param {object} legend - legend object.
 * @param {boolean} [isInteractive=true] - enable / disable interactivity of legends
 * @param {boolean} [isSquare=true] - sets square / bar legends
 * @param {boolean} [isReverse=false] - reverses the order of legend items
 * @returns {void}
 */

export const customizeLegend = (
  legend,
  isInteractive = true,
  isSquare = true,
  isReverse = false
) => {
  // set legend style
  legend.userClassName = "hbr-type-body2";
  legend.contentAlign = "right";
  if (isInteractive === true) {
    legend.position = "top";
    legend.paddingBottom = 8;
  } else {
    legend.position = "bottom";
    legend.itemContainers.template.clickable = false;
    legend.itemContainers.template.focusable = false;
    legend.itemContainers.template.cursorOverStyle =
      am4core.MouseCursorStyle.default;
  }
  // set custom legend icon style
  legend.useDefaultMarker = false;
  legend.markers.template.width = 20;
  legend.markers.template.height = 20;
  const marker = legend.markers.template.children.getIndex(0);
  marker.cornerRadius(0, 0, 0, 0);
  // reverse legend items order for stacked chart
  if (isSquare === true) {
    marker.strokeWidth = 0;
    marker.width = 16;
    marker.height = 16;
    marker.horizontalCenter = "left";
  } else {
    marker.width = 20;
    marker.height = 3;
    marker.valign = "middle";
  }
  if (isReverse === true) legend.reverseOrder = true;
};

export const customizeAnomalyLegend = (legend, color) => {
  legend.itemContainers.template.clickable = false;
  legend.itemContainers.template.focusable = false;
  legend.itemContainers.template.cursorOverStyle =
      am4core.MouseCursorStyle.default;
  var marker = legend.markers.template;
  marker.disposeChildren ();
  marker.width = 0;
  marker.height = 0;

  legend.contentAlign = "right";
  legend.userClassName = "hbr-type-body2"
  legend.dx = -110;
  legend.dy = 36;
  let triangle = marker.createChild(am4core.Triangle);
  triangle.width = 10;
  triangle.height = 10;
  triangle.rotation = 180;
  triangle.valign = "middle";
  triangle.dy = -6;
  triangle.fill = color
}

/**
 * Description: This method gives tooltip html string with static values
 * @param {object} series - am4 chart series object.
 * @param {object} data - tooltip data
 * @param {string} [date=""] - tooltip date
 * @param {string} [title=""] - tooltip title
 * @returns {string} - tooltip html string
 */

export const getTooltipHTMLString = (series, data, date = "", title = "") => {
  let tooltipHTML = "";
  if (title || date)
    tooltipHTML += `
      <div class="ttip-header">
        ${title ? `${title}&nbsp;` : ""}
        <span class="date">${date}</span>
      </div>
    `;
  tooltipHTML += `
    <table class="ttip-content">
    ${series
      .map(item => {
        if (!item.isHidden) {
          const legendColor = item.stroke.hex === colors.gray100.toLowerCase()
            ? item.fill.hex : item.stroke.hex;
          const value = data.tooltipData === undefined
            ? data[item.dataFields.valueY]
            : data.tooltipData[item.dataFields.valueY];
          const legendClass =
            item.stacked === true ? "square-legend" : "bar-legend";
          return `
            <tr>
              <td>
                <div class="flex-items">
                  <span
                    class=${legendClass}
                    style="background-color:${legendColor};"
                  ></span>
                  ${item.name || data.title || ""}
                </div>
              </td>
              <td>${value}</td>
            </tr>
          `;
        }
        return "";
      })
      .join("")}
    </table>
  `;
  return tooltipHTML;
};

/**
 * Description: This method gives approximate min height of gantt chart
 * @param {object | any[] | number} data - data to get number of columns
 * @returns {number} - min height in pixels
 */

export const getGanttChartHeight = data => {
  const minHeight = 150;
  let length = 0;
  if (Number.isInteger(data)) length = data;
  else if (Array.isArray(data)) length = data.length;
  else if (data instanceof Object) length = Object.entries(data).length;
  return length > 2.5 ? (length * 22) + 100 : minHeight;
};

export const getTooltipDate = (dataIdx, item, timestamp, tooltipTimestamp) => {
  if ((dataIdx === item.data.length - 2 || dataIdx === item.data.length - 1)) {
    if (dataIdx === item.data.length - 2) {
      if (tooltipTimestamp > timestamp && tooltipTimestamp <= timestamp + 900000) {
        return true
      }
    } else {
      if ((tooltipTimestamp > timestamp || tooltipTimestamp + 900000 > timestamp) &&
        tooltipTimestamp <= timestamp + 900000) {
        return true
      }
    }
  } else if (dataIdx !== item.data.length - 2 && dataIdx !== item.data.length - 1 &&
    timestamp % 100000 === 0 && tooltipTimestamp >= timestamp &&
    tooltipTimestamp < startOfHour(timestamp).valueOf() + 3600000) {
    return true
  }
  return false
}

export const getTooltipDateStacked = (dataIdx, item, timestamp, tooltipTimestamp) => {
  if (((dataIdx === item.data.length - 2 || dataIdx === item.data.length - 1) &&
    tooltipTimestamp < timestamp || tooltipTimestamp > timestamp + 900000) ||
    (dataIdx !== item.data.length - 2 && dataIdx !== item.data.length - 1 && tooltipTimestamp < timestamp ||
      tooltipTimestamp > timestamp + 3599999)) {
    return true
  }
  return false
}

export const getTimeState = (isSidebar = false) => {
  const { globalFilter } = reduxContext.store.getState().vanalytics.app;
  const v4Payload = isSidebar === false
    ? globalFilter.globalV4Payload
    : globalFilter.globalV4PayloadSidebar;
  return {
    interval: getTimeInterval(v4Payload.time_frame),
    min: v4Payload.entry_ts.start,
    max: v4Payload.entry_ts.end
  };
};
