import selectableColumnType from "@harbor/elements/utils/table/columnTypes/selectable";

/**
 * This callback type is called on the select event
 *
 * @callback selectCallback
 * @param {[string]} selections is the selected rows's ids
 * @param {[object]} source is the table data
 * @returns {undefined}
 */
/**
 * Description: get the selectable column object
 * @param {object} store is the table store
 * @param {selectCallback} selectCallback is called on the select event
 * @returns {object} - is the column object
 */
export const getSelectableColumn = (store, selectCallback = () => null) => {
  const selectableRowsHook = store.getHook("selectableRows");
  return {
    id: "selectable_col",
    ...selectableColumnType({ store }),
    columnTemplate: createElement => {
      const { source, selected, query: { maxSelections } } = store.getState();
      const rowCount = source.length;
      const maxRowSelections =
        rowCount > 0 && rowCount < maxSelections ? rowCount : maxSelections;
      return [createElement("hbr-checkbox", {
        indeterminate: selected.length > 0 && selected.length < maxRowSelections,
        checked: selected.length === maxRowSelections,
        disabled: rowCount === 0,
        style: { margin: "var(--cell-padding)" },
        "data-testid": "checkbox-header",
        "onHbr-click": event => {
          event.target.blur();
          const checked = event.target.checked;
          const selections =
            checked === true ? source.slice(0, 5).map(item => item.id) : [];
          selectableRowsHook.setSelected(selections);
          selectCallback(selections, source);
        }
      })];
    },
    cellTemplate: (createElement, props) => {
      const uniqueId = props.model.id;
      const { source, selected, query: { maxSelections } } = store.getState();
      return createElement("hbr-checkbox", {
        checked: selected.includes(uniqueId) ? true : undefined,
        "data-rowIndex": props.rowIndex,
        "data-testid": uniqueId,
        intermediate: false,
        disabled: selected.length >= maxSelections && !selected.includes(uniqueId),
        "onHbr-click": event => {
          event.target.blur();
          const { selected: current } = store.getState();
          const selections = [...current];
          const selectionIndex = selections.indexOf(uniqueId);
          if (selectionIndex === -1) selections.push(uniqueId);
          else selections.splice(selectionIndex, 1);
          selectableRowsHook.setSelected(selections);
          selectCallback(selections, source);
        }
      });
    }
  }
};

/**
 * Description: get table column cell template with info icon and tooltip
 * @param {string} id is tooltip id (unique)
 * @param {string} msg is the toolip text content
 * @param {Function} createElement is an internal function of harbor to create elements
 * @param {object} props - is the column object with column properties
 * @returns {HTMLElement} - is the element returned by the createElement function
 */
export const getColumnTemplateWithInfo = (id, msg, createElement, props) => {
  const content = createElement("div", {
    class: "hbr-css__table-cell-align-center",
    innerHTML: `
      ${props.name}
      <hbr-icon
        size="18px"
        style="margin-left: 4px; cursor: pointer;" name="info" id="${id}"
      >
      </hbr-icon>
      <hbr-portal>
        <hbr-tooltip anchor="#${id}">
          <div slot="content">
            ${msg}
          </div>
        </hbr-tooltip>
      </hbr-portal>
    `
  });
  return content;
};

/**
 * This callback type is called on the click event of the link.
 *
 * @callback linkClickCallback
 * @param {object} history is the history object of the react router library
 * @param {object} model is the row data
 * @returns {undefined}
 */
/**
 * Description: get table data cell template with link
 * @param {object} history is the history object of the react router library
 * @param {linkClickCallback} onClick is called on the click event of the link
 * @param {Function} createElement is an internal function of harbor to create elements
 * @param {object} data - this has row data (model) and the column property name (prop)
 * @returns {HTMLElement} - is the element returned by the createElement function
 */
export const getLinkCellTemplate = (
  history,
  onClick,
  createElement,
  { model, prop }
) => {
  const linkElement = createElement(
    "div",
    {
      class: "table-link",
      onClick: () => onClick(history, model)
    },
    model[prop]
  );
  return createElement("div", { class: "flex-items" }, linkElement);
};

/**
 * Description: get table data cell template with toolip
 * @param {string} tableId is the table id (unique)
 * @param {Function} createElement is an internal function of harbor to create elements
 * @param {object} data - this has row data (model) and the column property name (prop)
 * @returns {HTMLElement} - is the element returned by the createElement function
 */
export const getCellTemplateWithTooltip = (
  tableId,
  createElement,
  { model, prop }
) => {
  const id = `${tableId}_${model.id}_${prop}`;
  return createElement("div", {
    class: "flex-items",
    innerHTML: `
      <div id="${id}" class="overflow-ellipsis">${model[prop]}</div>
      <hbr-portal>
        <hbr-tooltip anchor="#${id}">
          <div slot="content">${model[prop]}</div>
        </hbr-tooltip>
      </hbr-portal>
    `
  });
};
