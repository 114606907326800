
import moment from "moment";
import _ from "lodash";
import { saveAs } from "file-saver";
import apiService from "../config/api-config";
import { getApplicationsTableList } from "./views/applications";
import timeFilterConfig from "../generics/config/time-filter-config";
import { getCookie, deleteCookie } from "../serviceWorker";
import {
  shortDisplayDate,
  displayUserExpChartTooltp,
  getStartOfHour,
  getEndOfHour,
  getTimePeriodELT,
  displayDateTime
} from "./displayTime";
import { getThemeColors, meanQuality, colors, statusColors } from "./colors"
import i18nMessageBundle from "amdi18n-loader!./nls/i18n";
import i18n from "amdi18n-loader!../config/nls/i18n";
import { BASE_URL } from "../apis/apiConstants";
import {
  PAGE,
  PAGE_NAME,
  FORMAT_PAGE_NAME,
  FORMAT_CSV_PAGE_NAME,
  defaultCellValue,
  timeFilterTypes,
  timeFilterTypesCustomV4,
  timeFilters5m,
  timeMS
} from "./enums";


// Standard quality ranges across app
export const qoeRanges = {
  good: { min: 8, max: 10 },
  fair: { min: 5, max: 8 },
  poor: { min: 0, max: 5 }
};
const availabilityRanges = {
  good: { min: 95, max: 100 },
  fair: { min: 80, max: 95 },
  poor: { min: 0, max: 80 }
};

export const getCustomListApi = async (
  props,
  globalV4PayloadReport,
  isReporting
) => {
  const {
    setAggregateAppData,
    setAppsWithFamilySLA,
    setOverlayReload,
    globalFilter
  } = props;

  let promisesQueue = [];
  const defaultRequest = isReporting ? { ...globalV4PayloadReport } : { ...globalFilter.globalV4Payload };
  defaultRequest.sort = { usage: "desc" };
  /* This will call custom list api whenever we change site from site filter */
  promisesQueue.push(apiService.getTableCustomList(defaultRequest));
  Promise.all([...promisesQueue]).then(aggregateApplicationsResults => {
    setAggregateAppData(aggregateApplicationsResults[0]);
    let tableList = getApplicationsTableList(
      aggregateApplicationsResults[0].data
    );
    setAppsWithFamilySLA(tableList);
    setOverlayReload(false);
  });
};

// Given a value, return its QOE score
export const getQoeScore = value => {
  return getScore(value, qoeRanges);
};

// Given a value, return its availability score
export const getAvailabilityScore = value => {
  return getScore(value, availabilityRanges);
};

const getScore = (value, ranges) => {
  //i.e. range: {poor: {min: 0, max: 5}fair: {min: 5, max: 8},good: {min: 8, max: 10}}
  for (const [quality, range] of Object.entries(ranges)) {
    if (value >= range.min && value <= range.max) {
      return quality;
    }
  }
  return null;
};

// Get formatted filter items
export const getFilterItems = items => {
  return Object.values(items).map(item => {
    const { label, value, additionalText } = item;
    return { label, value, additionalText };
  });
};

// Return true if current compare to previous props changed, otherwise false
export const hasFilterChanged = (curr, prev) => {
  // Compare current and previous time period arrays
  const selectedTime = curr.timePeriodSelected;
  const prevSelectedTime = prev.timePeriodSelected;
  const currentTimeStamp = curr.currentTimeStamp;
  const prevTimeStamp = prev.currentTimeStamp;

  //Compare current and previous applied sites
  const selectedSite = curr.selectedSite;
  const prevSelectedSite = prev.selectedSite;
  // Compare current and previous selected overlay
  const currOverlay = curr.selectedOverlay;
  const prevOverlay = prev.selectedOverlay;
  const overlayChange = currOverlay !== prevOverlay;

  const timeFilterChange =
    prevSelectedTime &&
    !isCustom(selectedTime) &&
    selectedTime !== prevSelectedTime;
  const siteChange = selectedSite !== prevSelectedSite;
  const currentTimeStampChange =
    currentTimeStamp && currentTimeStamp !== prevTimeStamp;
  //Compute final result based on all props check
  const isChanged =
    overlayChange || timeFilterChange || siteChange || currentTimeStampChange;
  //Compare custom time filter dates
  if (isCustom(selectedTime) && !isChanged) {
    const _curr = curr.selectedCustomTime;
    const _prev = prev.selectedCustomTime ? prev.selectedCustomTime : prev;

    if (_.isEmpty(_curr)) return false;

    // Compare custom time filter dates by computing current_period[0] and current_period[1] for current and previous props
    const currCustomTimeCurrentPeriodZero = _curr && _curr.current_period[0];
    const prevCustomTimeCurrentPeriodZero = _prev.timeFilter
      ? _prev.timeFilter.current_period[0]
      : _prev.current_period[0];

    const currCustomTimeCurrentPeriodOne = _curr && _curr.current_period[1];
    const prevCustomTimeCurrentPeriodOne = _prev.timeFilter
      ? _prev.timeFilter.current_period[1]
      : _prev.current_period[1];

    const customTimeCurrentPeriodZero =
      currCustomTimeCurrentPeriodZero !== prevCustomTimeCurrentPeriodZero;
    const customTimeCurrentPeriodOne =
      currCustomTimeCurrentPeriodOne !== prevCustomTimeCurrentPeriodOne;

    //if custom time filter dates are different return true, otherwise false
    if (customTimeCurrentPeriodZero || customTimeCurrentPeriodOne) {
      return true;
    } else {
      return false;
    }
  }

  return isChanged;
};
export const refresh = async props => {

  let res = await apiService.getCsrfToken(getCookie("okta-oauth-state"));

  if (res && res.data && res.status === 200 && res.data["loginState"]) {
    if (props.history.location.pathname.includes("anomalydetection")) {
      let randomNum = Math.random();
      if (props.history.location.pathname.endsWith("anomalydetection")) {
        props.setAnomalyRefresh(randomNum);
      } else {
        props.setKpiRefresh(randomNum);
      }
    } else if (!props.history.location.pathname.includes("accounts")) {
      const time = props.globalFilter.timePeriodSelected;

      props.setAppsWithFamilySLA(undefined);
      props.setAppsWithFamilySLA(props?.globalFilter?.app_data_with_family_sla)

      if (props.sideBar) {
        props.setSideBarTimeFilter(
          {
            current_period: props.globalFilter.timeFilter.current_period,
            previous_period: props.globalFilter.timeFilter.previous_period
          },
          time
        );
      } else {
        const timePeriod = getTimePeriodELT(time);

        props.setCtrlTimeFilter(timePeriod, time);
      }

      const currentTime = moment().valueOf();

      props.setRefreshScreen(currentTime);
    }
  } else {
    window.location.reload();
  }
};
export const isCustom = str => {
  return str === "Custom";
};

export const getProgressBarTooltipColorInRowOne = pathName => {
  if (pathName === PAGE.SCOPE) {
    return `<td style="color:${colors.gray15};padding:10px;letter-spacing:1px;font-size:14px">Setup Report Scope</td>`;
  } else {
    return `<td style="color:${colors.gray15};padding:10px;letter-spacing:1px;font-size:14px"><a>Setup Report Scope</a></td>`;
  }
};
export const getProgressBarTooltipColorInRowTwo = pathName => {
  // Tooltip for File Type
  if (pathName === PAGE.SCOPE) {
    return `<td style="color:${colors.gray40};padding:10px;letter-spacing:1px;font-size:14px">Select File Type</td>`;
  } else if (pathName === PAGE.FILETYPE) {
    return `<td style="color:${colors.gray15};padding:10px;letter-spacing:1px;font-size:14px">Select File Type</td>`;
  } else {
    return `<td style="color:${colors.gray15};padding:10px;letter-spacing:1px;font-size:14px"><a>Select File Type</a></td>`;
  }
};
export const getProgressBarTooltipColorInRowThree = pathName => {
  if (pathName === PAGE.SCOPE || pathName === PAGE.FILETYPE) {
    return `<td style="color:${colors.gray40};padding:10px;letter-spacing:1px;font-size:14px">Schedule Time</td>`;
  } else if (pathName === PAGE.SCHEDULE_TIME) {
    return `<td style="color:${colors.gray15};padding:10px;letter-spacing:1px;font-size:14px">Schedule Time</td>`;
  } else {
    return `<td style="color:${colors.gray15};padding:10px;letter-spacing:1px;font-size:14px"><a>Schedule Time</a></td>`;
  }
};
export const getProgressBarTooltipColorInRowFour = pathName => {
  if (
    pathName === PAGE.SCOPE ||
    pathName === PAGE.SCHEDULE_TIME ||
    pathName === PAGE.FILETYPE
  ) {
    return `<td style="color:${colors.gray40};padding:10px;letter-spacing:1px;font-size:14px">Delivery and Notification</td>`;
  } else if (pathName === PAGE.DELIVERY) {
    return `<td style="color:${colors.gray15};padding:10px;letter-spacing:1px;font-size:14px">Delivery and Notification</td>`;
  } else if (pathName === PAGE.SUMMARY) {
    return `<td style="color:${colors.gray15};padding:10px;letter-spacing:1px;font-size:14px"><a>Delivery and Notification</a></td>`;
  }
};
export const getProgressBarTooltipColorInRowFive = pathName => {
  if (pathName !== PAGE.SUMMARY) {
    return `<td style="color:${colors.gray40};padding:10px;letter-spacing:1px;font-size:14px">Summary</td>`;
  } else if (pathName === PAGE.SUMMARY) {
    return `<td style="color:${colors.gray15};padding:10px;letter-spacing:1px;font-size:14px">Summary</td>`;
  }
};
export const getBarSize = step => {
  switch (true) {
    case step === PAGE.SCOPE:
      return "20%";
    case step === PAGE.FILETYPE:
      return "40%";
    case step === PAGE.SCHEDULE_TIME:
      return "60%";
    case step === PAGE.DELIVERY:
      return "80%";
    default:
      return "100%";
  }
};

export const getPathName = location => {
  const path = location.pathname.split("/");

  if (path.length === 4 && validatePath(path[4])) {
    return location.pathname.split("/")[3];
  } else if (path.length === 5 && validatePath(path[5])) {
    return location.pathname.split("/")[4];
  }
};

export const getReportId = location => {
  return location.pathname.split("/")[4];
};

export const getStepFromPath = location => {
  const path = getPathName(location);

  switch (true) {
    case path === "setup-filetype":
      return 2;
    case path === "setup-report-scheduler":
      return 3;
    case path === "setup-report-email-delivery":
      return 4;
    case path === "setup-report-summary":
      return 5;
    default:
      return 1;
  }
};
export const getEditPath = location => {
  const path = getPathName(location);
  switch (true) {
    case path === "setup-report-scope":
      return true;
    case path === "setup-filetype":
      return true;
    case path === "setup-report-scheduler":
      return true;
    case path === "setup-report-email-delivery":
      return true;
    case path === "setup-report-summary":
      return true;
    default:
      return false;
  }
};
export const isWidgetGradeOut = location => {
  const path = location.pathname.split("/");

  if (path.length === 6 && validatePath(path[6])) {
    return true;
  } else {
    return false;
  }
};

export const createObjFromEmailList = list => {
  const arrEmails = list?.split(",");
  const textfieldgroup =
    arrEmails &&
    arrEmails.map((email, key) => {
      return {
        name: "recipientEmail",
        validator: "email",
        inputid: `recipientEmailId${key + 1}`,
        required: true,
        label: `${i18nMessageBundle.commonUtilEmail} ` + (key + 1),
        value: email,
        order: key,
        errormsg: ""
      };
    });
  return { textfieldgroup: textfieldgroup };
};

const validatePath = path => {
  if (path === PAGE.SCOPE || path === PAGE.SCHEDULE_TIME || PAGE.DELIVERY)
    return true;
  else return false;
};

const isExecutiveSummary = location => {
  return location.pathname.includes("/executive_summary");
};

const isApplicationSummary = location => {
  return location.pathname.includes("/application_summary");
};

const isSiteSummary = location => location.pathname.includes("/site_summary");

const isApp360Report = location => {
  return location.pathname.includes("/application_summary/");
};

export const isReport = location => {
  return location.pathname.includes("/report/");
};
const isGenerateReport = () => {
  return location.pathname.includes("report/setup");
};

export const isAccountsPage = () => {
  return location.pathname.includes(`${BASE_URL}/accounts`);
};

export const isOverviewPage = () => {
  return location.pathname.includes(`${BASE_URL}/overview`);
};

export const isAllSitesPage = () => {
  return location.pathname.includes(`${BASE_URL}/sites`);
};

export const isAnomalyPage = () => {
  return location.pathname.includes(`/anomalydetection`);
};

export const isSecurityPage = () => {
  return location.pathname.includes(`${BASE_URL}/threatintelligence`);
};

export const isTrafficLogsPage = () => {
  return location.pathname.includes(`${BASE_URL}/traffic-logs`);
};

export const isDeviceInfoPage = () => {
  return location.pathname.includes(
    `${BASE_URL}/predictivenetworks/applicationinformation/device`
  );
};

export const isPredictiveNetworksPage = () => {
  return location.pathname.includes(`${BASE_URL}/predictivenetworks`);
};

export const isReportsPage = () => {
  return location.pathname.includes(`${BASE_URL}/reports`);
};

export const getTitleHeader = location => {
  const {
    commonUtilExecutiveSummary,
    commonUtilAllApplicationsReport,
    commonUtilGenerateReport,
    commonUtilTemplatesSitesSummary,
    commonUtilEditReport,
    commonUtilTemplateSingleApp
  } = i18nMessageBundle;

  switch (true) {
    case isExecutiveSummary(location):
      return commonUtilExecutiveSummary;
    case isApp360Report(location):
      return commonUtilTemplateSingleApp;
    case isApplicationSummary(location):
      return commonUtilAllApplicationsReport;
    case isSiteSummary(location):
      return commonUtilTemplatesSitesSummary;
    case isGenerateReport(location):
      return commonUtilGenerateReport;
    default:
      return commonUtilEditReport;
  }
};

export const titleStyle = location => {
  return isExecutiveSummary(location) ||
    isApplicationSummary(location) ||
    isSiteSummary(location)
    ? {
      position: "initial"
    }
    : {};
};

export const isDefaultHeader = location => {
  return (
    isExecutiveSummary(location) ||
    isApplicationSummary(location) ||
    isSiteSummary(location) ||
    isReport(location)
  );
};

export const isExecutiveTemplate = type => {
  return type === PAGE_NAME.EXECUTIVE || type === FORMAT_PAGE_NAME.EXECUTIVE;
};

export const isAllApplicationsTemplate = type => {
  return (
    type === PAGE_NAME.ALL_APPLICATIONS ||
    type === FORMAT_PAGE_NAME.ALL_APPLICATIONS
  );
};

export const isAllSitesTemplate = type => {
  return type === PAGE_NAME.ALL_SITES || type === FORMAT_PAGE_NAME.ALL_SITES;
};

export const isAllApplicationsTemplateCSV = type => {
  return (
    type === FORMAT_CSV_PAGE_NAME.ALL_APPLICATIONS ||
    type === FORMAT_CSV_PAGE_NAME.APP_360
  );
};

export const isAllSitesTemplateCSV = type => {
  return type === FORMAT_CSV_PAGE_NAME.ALL_SITES;
};
export const isApp360 = type => {
  return type === PAGE_NAME.APP_360;
};

export const isAll = str => {
  return str === "all";
};

export const formatPageName = (type, fileType, scope) => {
  if (fileType === "pdf") {
    switch (true) {
      case isExecutiveTemplate(type):
        return FORMAT_PAGE_NAME.EXECUTIVE;
      case isAllApplicationsTemplate(type):
        return FORMAT_PAGE_NAME.ALL_APPLICATIONS;
      case isAllSitesTemplate(type):
        return FORMAT_PAGE_NAME.ALL_SITES;
      case isApp360(type):
        return FORMAT_PAGE_NAME.APP_360;
      default:
        return FORMAT_PAGE_NAME.SITE_360;
    }
  } else if (fileType === "csv") {
    //TODO
    switch (true) {
      case (isAllApplicationsTemplate(type) && _.isEmpty(scope.application)) ||
        scope.application == "all":
        return FORMAT_CSV_PAGE_NAME.ALL_APPLICATIONS;
      case isAllApplicationsTemplate(type) && !_.isEmpty(scope.application):
        return FORMAT_CSV_PAGE_NAME.APP_360;
      case isAllSitesTemplate(type):
        return FORMAT_CSV_PAGE_NAME.ALL_SITES;
    }
  }
};

export const formatScope = (scope, name = null) => {
  switch (true) {
    case isAllApplicationsTemplate(name) && !_.isEmpty(scope.application):
      return (
        `{` +
        '"' +
        `site_id` +
        '"' +
        `:[` +
        '"' +
        '"' +
        `],` +
        '"' +
        "application" +
        '"' +
        `:[` +
        '"' +
        `${scope.application}` +
        '"' +
        `]}`
      );
    case isAllApplicationsTemplate(name) && _.isEmpty(scope.application):
      return (
        `{` +
        '"' +
        `site_id` +
        '"' +
        `:[` +
        '"' +
        '"' +
        `],` +
        '"' +
        "application" +
        '"' +
        `:[` +
        '"' +
        `all` +
        '"' +
        `]}`
      );
    case isAllSitesTemplate(name) && !_.isEmpty(scope.site_id):
      return (
        `{` +
        '"' +
        `site_id` +
        '"' +
        `:[` +
        '"' +
        `${scope.site_id}` +
        '"' +
        `],` +
        '"' +
        "application" +
        '"' +
        `:[` +
        '"' +
        '"' +
        `]}`
      );
    case isAllSitesTemplate(name) && _.isEmpty(scope.site_id):
      return (
        `{` +
        '"' +
        `site_id` +
        '"' +
        `:[` +
        '"' +
        `all` +
        '"' +
        `],` +
        '"' +
        "application" +
        '"' +
        `:[` +
        '"' +
        '"' +
        `]}`
      );
    case isExecutiveTemplate(name):
      return (
        `{` +
        '"' +
        `site_id` +
        '"' +
        `:[` +
        '"' +
        '"' +
        `],` +
        '"' +
        "application" +
        '"' +
        `:[` +
        '"' +
        '"' +
        `]}`
      );
    default:
      return (
        `{` +
        '"' +
        `site_id` +
        '"' +
        `:[` +
        '"' +
        `${scope.site_id}` +
        '"' +
        `],` +
        '"' +
        "application" +
        '"' +
        `:[` +
        '"' +
        `${scope.application}` +
        '"' +
        `]}`
      );
  }
};

export const isRunNow = input => {
  return input === "once";
};

export const isFreqNow = input => {
  return input === "now";
};

export const isRecurring = input => {
  return input === "recurring";
};

export const is1Hour = input => {
  return input === "1";
};

export const is3Hours = input => {
  return input === "3";
};

export const is6Hours = input => {
  return input === "6";
};

export const isDaily = input => {
  return input === "daily" || input === "24";
};

export const isWeekly = input => {
  return input === "weekly" || input === "168";
};

export const isMonthly = input => {
  return input === "monthly" || input === "720";
};

export const isOnce = input => {
  return input === "once";
};

export const getFreqMessage = input => {
  switch (true) {
    case isDaily(input):
      return i18nMessageBundle.commonUtilDaily;
    case isWeekly(input):
      return i18nMessageBundle.commonUtilLastWeek;
    case isMonthly(input):
      return i18nMessageBundle.commonUtilLastMonth;
  }
};

export const getTimeFilter = input => {
  switch (true) {
    case is1Hour(input):
      return "1";
    case is3Hours(input):
      return "3";
    case is6Hours(input):
      return "6";
    case isDaily(input):
      return "24";
    case isWeekly(input):
      return "168";
    case isMonthly(input):
      return "720";
    default:
      return "12";
  }
};

export const getRepeatOn = day => {
  switch (true) {
    case day === "Monday":
      return 1;
    case day === "Tuesday":
      return 2;
    case day === "Wednesday":
      return 3;
    case day === "Thursday":
      return 4;
    case day === "Friday":
      return 5;
    case day === "Saturday":
      return 6;
    case day === "Sunday":
      return 0;
    default:
      return -1;
  }
};

export const getRepeatOnDay = repeat_on => {
  switch (repeat_on) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    case 0:
      return "Sunday";
    default:
      return shortDisplayDate(new Date());
  }
};

export const getWorkflowState = () => {
  const workflow = localStorage.getItem("workflow");
  return workflow ? JSON.parse(workflow) : {};
};

export const convertHoursToFreq = h => {
  const days = Math.trunc(h / 24);
  switch (true) {
    case days === 1:
      return "daily";
    case days === 7:
      return "weekly";
    case days === 30:
      return "monthly";
    default:
      return "daily";
  }
};

export const isReportingApi = () => {
  return location.pathname.includes("/api/dataservice/reporting/");
};

export const isGenerateReportWorkflow = pathname => {
  if (pathname.includes("report/setup-report-scope")) return true;
  else return false;
};

export const isSortingAscending = type => {
  if (type === "asc") return true;
  else return false;
};

export const isReportsView = location => {
  return (
    location.pathname.includes("/report/") ||
    location.pathname.includes("/reporting/")
  );
};

export const isAdminRole = roles => {
  return Array.isArray(roles) && roles.length > 0 && roles.includes("admin");
};

export const sortedSitesList = sitesList => {
  return sitesList.sort((a, b) => {
    return parseInt(a.value) - parseInt(b.value);
  });
};

export const getRandomString = len => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charsetLen = charset.length;
  const values = new Uint8Array(len);
  // crypto.getRandomValues() generates cryptographically strong random values
  window.crypto.getRandomValues(values);
  const passKey = Array.from(values, value => charset[value % charsetLen]).join(
    ""
  );
  return passKey;
};

export const generateUID = length => {
  return window
    .btoa(
      Array.from(window.crypto.getRandomValues(new Uint8Array(length * 2)))
        .map(b => String.fromCharCode(b))
        .join("")
    )
    .replace(/[+/]/g, "")
    .substring(0, length);
};

export const timeUnits = [
  { name: "year", timestamp: 365 * 24 * 60 * 60 * 1000 },
  { name: "month", timestamp: 30 * 24 * 60 * 60 * 1000 },
  { name: "week", timestamp: 7 * 24 * 60 * 60 * 1000 },
  { name: "day", timestamp: 1 * 24 * 60 * 60 * 1000 },
  { name: "hour", timestamp: 1 * 60 * 60 * 1000 },
  { name: "minute", timestamp: 1 * 60 * 1000 },
  { name: "second", timestamp: 1 * 1000 }
];

export const getInterval = data => {
  let step = 0;
  data
    .sort((a, b) => b.date - a.date)
    .forEach((item, dex, self) => {
      if (dex < self.length - 1) {
        const newStep = item.date - self[dex + 1].date;
        if (step === 0) {
          step = newStep;
        } else {
          step = newStep < step ? newStep : step;
        }
      }
    });
  let timeUnit = timeUnits[5].name;
  let count = 1;

  if (step > timeUnits[0].timestamp) {
    timeUnit = timeUnits[0].name;
    count = Math.floor(step / timeUnits[0].timestamp);
  } else if (step > timeUnits[1].timestamp) {
    timeUnit = timeUnits[1].name;
    count = Math.floor(step / timeUnits[1].timestamp);
  } else if (step > timeUnits[2].timestamp) {
    timeUnit = timeUnits[2].name;
    count = Math.floor(step / timeUnits[2].timestamp);
  } else if (step > timeUnits[3].timestamp) {
    timeUnit = timeUnits[3].name;
    count = Math.floor(step / timeUnits[3].timestamp);
  } else if (step > timeUnits[4].timestamp) {
    timeUnit = timeUnits[4].name;
    count = Math.floor(step / timeUnits[4].timestamp);
  } else if (step > timeUnits[5].timestamp) {
    timeUnit = timeUnits[5].name;
    count = Math.floor(step / timeUnits[5].timestamp);
  }
  const result = { timeUnit, count };
  return result;
};

export const hasTimeFilterChanged = (curr, prev) => {
  return _.isEqual(curr, prev) ? false : true;
};

export const getLineChartDummyData = data => {
  let dummyData = [];
  let responseDate = data[0].date
    ? moment(data[0].date)
    : moment(data[0].entry_time);
  let roundDown = new Date(responseDate.startOf("hour"));
  let roundUp = new Date(responseDate.endOf("hour"));
  dummyData.push({
    date: roundDown,
    value: data[0].value ? data[0].value : data[0].vqoe_score
  });
  dummyData.push({
    date: roundUp,
    value: data[0].value ? data[0].value : data[0].vqoe_score
  });

  return dummyData;
};
export const setLocalStorageFlag = (key, val) => {
  localStorage.setItem(key, val);
};

export const getLocalStorageFlag = key => {
  return localStorage.getItem(key);
};

export const removeLocalStorageFlag = key => {
  return localStorage.removeItem(key);
};

export const removeItemsLocalStorage = () => {
  removeLocalStorageFlag("currentOverlay");
  removeLocalStorageFlag("currentOverlayName");
  removeLocalStorageFlag("onboardingStatus");
  removeLocalStorageFlag("roles");
  removeLocalStorageFlag("timePeriodSelected");
  removeLocalStorageFlag("currentSite");
};

export const isCsvHeader = location => {
  return (
    location.pathname.includes("/all_sites_csv") ||
    location.pathname.includes("/all_apps_csv") ||
    location.pathname.includes("/app360_csv")
  );
};

export const arrayToObject = (array, attr) =>
  array.reduce((obj, item) => {
    obj[item[attr]] = item[attr];
    return obj;
  }, {});

export const arrayToObjectExtended = (array, attr) =>
  array.reduce((obj, item) => {
    obj[item[attr]] = item;
    return obj;
  }, {});

export const getTextValueTopSites = sitesView => {
  switch (true) {
    case sitesView === "availability":
      return {
        viewBy: i18nMessageBundle.commonUtilViewByWorst,
        secondColHeader: i18nMessageBundle.commonUtilAvailability,
        thirdColHeader: i18nMessageBundle.commonUtilUsageLabel
      };
    default:
      return {
        viewBy: i18nMessageBundle.commonUtilViewByTopRecom,
        secondColHeader: i18nMessageBundle.commonUtilRecomPathQuality,
        thirdColHeader: i18nMessageBundle.commonUtilState
      };
  }
};

export const isSiteViewedByAvailability = input => {
  return input === "availability";
};

export const getTipGanttChart = (
  data,
  isDynamicDate = true,
  isOptionalElementShown
) => {
  const currentPathsColor = isDynamicDate
    ? data.currentPathsColor
    : `{currentPathsColor}`;
  const label = isDynamicDate ? data.label : `{label}`;
  const meanQ = isDynamicDate ? data.meanQuality : `{meanQuality}`;
  const showMeanElement = isOptionalElementShown
    ? `<table>
        <tr>
          <td id=${"td-row-mean-color"}>${meanQ}</td>
          <td id=${"td-row-mean-label"}>${i18nMessageBundle.commonUtilappGroupPerSite.meanQualityTipLabel
    }</td>
        </tr>
      </table>
      <div class=table-divider></div>`
    : ``;
  const showDatesElement = isOptionalElementShown
    ? `<div class=table-divider></div>
      <table class=table-bottom>
        <tr>
          <td>${i18nMessageBundle.commonUtilappGroupPerSite.userExpChartFromLabel
    } ${isDynamicDate ? data.fromDateFormated : ""
    } {fromDateFormated}</td>
          <td class=table-bottom>${i18nMessageBundle.commonUtilappGroupPerSite.userExpChartToLabel
    } ${isDynamicDate ? data.toDateFormated : ""}{toDateFormated}</td>
        </tr>
      </table>`
    : ``;

  return `
    ${showMeanElement}
    <table id=${"loadBalaceTooltipData"}>
      <tr>
        <td id=${"loadBalaceTooltipSingle"}>
          <span id=${"currentColor"} style="background:${currentPathsColor}">
          </span>
        </td>
        <td>
          <span id=${"loadBalaceTooltipPathName"}>${label}
          </span>
        </td>
        <td></td>
        <td></td>
      </tr>
    </table>
    <div class=table-divider></div>
    <table>
      <tr id=${"tooltip-title-row"}>
        <th>${i18nMessageBundle.commonUtilappGroupPerSite.currentTipLabel}</th>
        <td></td>
        <th>${i18nMessageBundle.commonUtilappGroupPerSite.recommendedTipLabel
    }</th>
        <td>
        </td>
      </tr>
      ${Array(data.lengthOfPaths)
      .fill()
      .map(
        (pathElement, pathIndex) => `
              <tr id=${"loadBalaceTooltipData"}>
                <td tabindex="0" id=${"loadBalaceTooltip"}>
                  <span id=${"loadBalaceTooltipColorMark"}
                    style="background: ${data &&
          data.currentPathsColors[pathIndex]}">
                  </span>
                </td>

                <td>
                  <span id=${"loadBalaceTooltipPathName"}>
                  ${data && data.currentPaths[pathIndex]}
                  </span>
                </td>

                <td tabindex="1" id=${"loadBalaceTooltipSingle"}>
                  <span id=${"loadBalaceTooltipColorMark"}
                    style="background:${data
            ? data.recommendedPaths[pathIndex] &&
            data.recommendedPaths[pathIndex].color
            : "none"
          }">
                  </span>
                </td>

                <td>
                 <span id=${"loadBalaceTooltipPathName"}>${data &&
            data.recommendedPaths[pathIndex] &&
            data.recommendedPaths[pathIndex].pathName
            ? data.recommendedPaths[pathIndex].pathName
            : ``
          }
                  </span>
                </td>
              </tr>
      `
      )
      .join("")}
    </table>
    ${showDatesElement}
  `;
};

export const getTipBulletColumnChart = (data = "") => {
  return `
    <table class=table-top>
      <tr>
        <td id=${"td-row-1"}>${data &&
    data.recommendationQuality}{recommendationQuality}% </td>
        <td id=${"td-row-2"}>${i18nMessageBundle.commonUtilappGroupPerSite.userExpChartRecomLabel
    }</td>
      </tr>
      <tr>
        <td id=${"td-row-1"}>${data &&
    data.defaultQuality}{defaultQuality}%</td>
        <td id=${"td-row-2"}>${i18nMessageBundle.commonUtilappGroupPerSite.userExpChartCurrentPathLabel
    }</td>
      </tr>
    </table>
    <div class=table-divider></div>
    <table class=table-bottom>
      <tr>
        <td id=${"td-row-num"}>${data &&
    data.totalNumUsers} {totalNumUsers}</td>
        <td>${i18nMessageBundle.commonUtilappGroupPerSite.userExpChartActiveUsrLabel
    }</td>
      </tr>
    </table>
    <div class=table-divider></div>
    <table class=table-bottom>
      <tr>
        <td id=${"date-row"}>${i18nMessageBundle.commonUtilappGroupPerSite.userExpChartFromLabel
    }
        ${data && data.fromDateFormated} {fromDateFormated}</td>
        <td id=${"date-second-col"}>${i18nMessageBundle.commonUtilappGroupPerSite.userExpChartToLabel
    }
        ${data && data.toDateFormated} {toDateFormated}</td>
      </tr>
    </table>`;
};

export const getTooltipMissingData = data => {
  return `
    <table id=${"no-data-tooltip"}>
      <tr>
        <td id=${"td-row-no-data"}>${i18nMessageBundle.commonUtilMissingData
    }</td>
      </tr>
      <tr>
        <td id=${"td-row-no-data"}>${i18nMessageBundle.commonUtilLackTelemetryData
    }</td>
      </tr>
      <tr>
        <td id=${"td-row-no-data"}>${i18nMessageBundle.commonUtilStagnation
    }</td>
      </tr>
    </table>
    <div class=table-divider></div>
    <table>
      <tr>
        <td id=${"no-data-from-line"}>${data}</td>
      </tr>
    </table>`;
};

export const getTooltipNoData = data => {
  return `
    <table id=${"no-data-tooltip"}>
      <tr>
        <td id=${"td-row-no-data"}>${i18nMessageBundle.commonUtilMissingData
    }</td>
      </tr>
      <tr>
        <td id=${"td-row-no-data"}>${i18nMessageBundle.commonUtilLackTelemetry
    }</td>
      </tr>
      <tr>
        <td id=${"td-row-no-data"}>${i18nMessageBundle.commonUtilStagnation
    }</td>
      </tr>
      <tr>
        <td id=${"td-row-no-data"}></td>
      </tr>
      <tr>
        <td id=${"td-row-no-data"}></td>
      </tr>
    </table>
    <div class=table-divider></div>
    <table>
      <tr>
        <td id=${"no-data-from"}>${displayUserExpChartTooltp(
      data.fromDate
    )}</td>
        <td id=${"no-data-to"}>${displayUserExpChartTooltp(data.toDate)}</td>
      </tr>
      <tr>
        <td id=${"no-data-from"}> ${""}</td>
        <td id=${"no-data-to"}>${""}</td>
      </tr>
    </table>
    <table>
      <tr>
        <td id=${"td-row-no-data-bottom"}>${i18nMessageBundle.commonUtilAlldataMissing
    }</td>
      </tr>
    </table>`;
};

export const getChartHight = size => {
  switch (true) {
    case size === 1:
      return 160;
    case size === 2:
      return 190;
    case size === 3:
      return 210;
    case size === 4:
      return 280;
    case size === 5:
      return 310;
    default:
      return 350;
  }
};

export const getColorFromMeanQuality = mean => {
  const meanQColorList = meanQuality;
  switch (true) {
    case parseInt(mean) > 94:
      return statusColors.good;
    case parseInt(mean) > 90:
      return meanQColorList[0];
    case parseInt(mean) > 80:
      return meanQColorList[1];
    case parseInt(mean) > 70:
      return meanQColorList[2];
    case parseInt(mean) > 60:
      return meanQColorList[3];
    case parseInt(mean) > 50:
      return meanQColorList[4];
    case parseInt(mean) > 40:
      return meanQColorList[5];
    case parseInt(mean) > 30:
      return meanQColorList[6];
    default:
      return meanQColorList[7];
  }
};

export const getMissingKeys = (current, next) => {
  let missingKeys = current.filter(ckey => {
    if (
      ckey !== "date" &&
      !next.some(nkey => ckey.toString() === nkey.toString())
    ) {
      return ckey;
    }
  });
  return missingKeys;
};

export const compareAndMatchCircuits = (usage, ciruitData) => {
  usage.sort((a, b) => (a.date > b.date ? 1 : -1));
  if (usage && usage.length > 0 && ciruitData && ciruitData.length > 0) {
    for (var i = 0; i < usage.length; i++) {
      if (
        usage[i] &&
        usage[i + 1] &&
        !usage[i].message &&
        !usage[i + 1].message
      ) {
        let missingKeys = getMissingKeys(
          Object.keys(usage[i]),
          Object.keys(usage[i + 1])
        );
        if (
          (usage[i].date.getHours() + 1 !== usage[i + 1].date.getHours() &&
            !(
              usage[i].date.getHours() === 23 &&
              usage[i + 1].date.getHours() === 0
            )) ||
          (usage[i].date.getHours() + 1 === usage[i + 1].date.getHours() &&
            usage[i].date.getDate() !== usage[i + 1].date.getDate())
        ) {
          let newEntry = { ...usage[i] };
          newEntry.date = new Date(newEntry.date.getTime() + 3599999);
          newEntry.message = "Entry";
          usage.push(newEntry);
        } else if (missingKeys.length > 0) {
          let missingEntry = { ...usage[i] };
          missingEntry.date = new Date(missingEntry.date.getTime() + 3599999);
          missingEntry.message = "Entry";
          missingKeys.map(mkey => {
            missingEntry[mkey] = usage[i][mkey];
          });
          usage.push(missingEntry);
        }
      }
    }
  }
  // Adding last toDate entry
  usage.sort((a, b) => (a.date > b.date ? 1 : -1));
  ciruitData.sort((a, b) => (a.fromDate > b.fromDate ? 1 : -1));
  let entry = { ...usage[usage.length - 1] };
  if (ciruitData[ciruitData.length - 1]) {
    entry.date = new Date(ciruitData[ciruitData.length - 1].toDate);
    entry.message = "Entry";
    usage.push(entry);
  }
  return usage;
};

export const compareAndMatch = (Data, ciruitData) => {
  if (ciruitData && ciruitData.length > 0 && Data && Data.length > 0) {
    ciruitData.sort((a, b) => (a.fromDate > b.fromDate ? 1 : -1));
    let start_point = ciruitData[0].fromDate;
    let end_point = ciruitData[ciruitData.length - 1].toDate;
    if (new Date(start_point) < new Date(Data[0].utcDate)) {
      Data.unshift({
        date: new Date(start_point),
        value: Data[0].value
      });
    }
    if (new Date(Data[Data.length - 1].utcDate) < new Date(end_point)) {
      Data.push({
        date: new Date(end_point),
        value: Data[Data.length - 1].value
      });
    }
    return Data;
  }
};

export const customizeByTimePeriod = (
  timePeriodSelected,
  twelveAndtwentyFour,
  sevenDays,
  rest
) => {
  let val = twelveAndtwentyFour;
  if (timePeriodSelected === "12" || timePeriodSelected === "24") {
    val = twelveAndtwentyFour;
  } else if (timePeriodSelected === "168") {
    val = sevenDays;
  } else {
    val = rest;
  }
  return val;
};

export const sortdata = (requestParam, sortedData) => {
  if (Object.keys(requestParam).length > 0 && sortedData.length > 0) {
    const { property, order } = requestParam.sort[0];
    const [first] = sortedData;
    const sortvaluetype = typeof first[property];
    return sortedData.sort((firstElement, secondElement) => {
      switch (sortvaluetype) {
        case "string": {
          //This is for String IP address sorting
          if (property === "user_ip") {
            const num1 = Number(
              firstElement[property]
                .split(".")
                .map(num => `000${num}`.slice(-3))
                .join("")
            );
            const num2 = Number(
              secondElement[property]
                .split(".")
                .map(num => `000${num}`.slice(-3))
                .join("")
            );
            return order === "asc" ? num1 - num2 : num2 - num1;
          } else {
            return order === "asc"
              ? firstElement[property] > secondElement[property]
                ? 1
                : -1
              : firstElement[property] < secondElement[property]
                ? 1
                : -1;
          }
        }
        case "number":
          return order === "desc"
            ? secondElement[property] - firstElement[property]
            : firstElement[property] - secondElement[property];
      }
    });
  } else {
    return sortedData;
  }
};

/**
 * description: This method creates a header for the reporting templates
 * with the overlay name and type of the template
 * @param {*} overlayName is a response from api that need to be displayed in the report header
 * @param {*} location is used to identify and display the template name ex: executive summary / application summary/ all sites summary
 */
export const getReportsHeader = (overlayName, location) => {
  return (
    <>
      <div className="app-header-workflow" style={titleStyle(location)}>
        <div className="mega-workflow">
          <div className="app-name">
            <div className="header-brand">
              <hbr-icon size="32px" name="cisco"></hbr-icon>
            </div>
            <div className="product-name">Catalyst SD-WAN</div>
          </div>
        </div>
        <div className="title-report-left">
          {overlayName !== "" ? overlayName : null}
        </div>
        <div className="title-report">
          <div>{getTitleHeader(location)}</div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </>
  );
};

/**
 * Description: This method creates a generic error object
 * @param {*} response is a response from api with the error config object
 * @param {*} message is the error message
 */

export const createGenericErrorObject = ({ response, message }) => {
  let errorObject = { errorCode: null, message: "" };
  if (response) {
    const responseData = response.data;
    errorObject.errorCode = response ? response.status : "500";
    if (responseData) {
      if (typeof responseData === "string") errorObject.message = responseData;
      else if (responseData instanceof Object) {
        if ("error" in responseData) {
          errorObject.message = responseData.error;
        } else if ("TAC_flag" in responseData) {
          errorObject = responseData;
        } else if ("error_code" in responseData) {
          errorObject.errorCode = responseData.error_code;
          errorObject.message = responseData.message;
        }
      }
    }
  }
  if (!errorObject.message) errorObject.message = message;
  return { errorObject };
};

/**
 * Description: validate if the list of sites contain any site with valid latitude and longitude
 * @param {*} sites is an array that contains a list of sites objets
 * each object contains details about site like latidute and longitude
 */
export const validateSitesLocation = sites => {
  let isUnknownSiteLocation = true;

  for (const siteIndex of sites) {
    if (
      (siteIndex.latitude == "0" && siteIndex.longitude == "0") ||
      siteIndex.latitude < "-65" ||
      siteIndex.latitude > "65"
    ) {
      isUnknownSiteLocation = false;
      break;
    }
  }
  return isUnknownSiteLocation;
};

/**
 *
 * Description: set the currentpath in the localstorage on page refresh
 *
 */
export const setCurrentPath = () => {
  switch (true) {
    case pathValidation(i18n.navConfigOverview):
      setActiveItem(i18n.navConfigOverview);
      break;
    case pathValidation(i18n.navConfigSites):
      setActiveItem(i18n.navConfigSites);
      break;
    case pathValidation(i18n.navConfigApplications) ||
      pathValidation(i18n.navConfigDashboard):
      setActiveItem(i18n.navConfigApplications);
      break;
    case pathValidation(i18n.navConfigCircuits):
      setActiveItem(i18n.navConfigCircuits);
      break;
    case pathValidation(i18n.navConfigRecommendations) ||
      pathValidation(i18n.navConfigPredictiveNetworks):
      setActiveItem(i18n.navConfigPredictiveNetworks);
      break;
    case pathValidation(i18n.navConfigReportsLabel):
      setActiveItem(i18n.navConfigReportsLabel);
      break;
    default:
      setActiveItem(i18n.navConfigOverview);
      break;
  }
};

/**
 * Description: Validate the location pathname
 * @param {*} label is a string that contains the navigation label
 *
 */
export const pathValidation = label => {
  if (label) {
    let lbl = label.toLowerCase();
    let loc = location.pathname.toLowerCase();
    return loc.includes(lbl);
  }
};

/**
 * Description: set localstorage value for currentPath
 * @param {*} val is a string that contains the current active navigation label
 *
 */
export const setActiveItem = val => {
  localStorage.setItem("currentPath", val);
};

/**
 * Description: set innerHTML in the browser DOM using dangerouslySetInnerHTML
 * @param {*} strHTML is a string with HTML content
 *
 */
export const createMarkup = strHTML => {
  return { __html: strHTML };
};

// customizeGrip for scroll bar
export const customizeGrip = (grip, am4core) => {
  grip.icon.disabled = true;
  grip.background.disabled = true;
  let line = grip.createChild(am4core.Rectangle);
  line.height = 80;
  line.width = 5;
  line.fillOpacity = 0.4;
  line.align = "center";
  line.valign = "middle";
};

/**
 * Description: Checks conditions to display comparison text in time filter section
 * @param {*} location is a react router's location object
 *
 */

export const showComparisonText = location => {
  if (location.pathname.startsWith(`${BASE_URL}/circuits`) || location.pathname.startsWith(`${BASE_URL}/threatintelligence`)) return false;
  return true;
};

export const isBandForecastingTabSelected = () => {
  return getLocalStorageFlag("bandForecastingTab") === "true";
};

/**
 *
 * @param {*} data that is being sorted
 * @param {*} actualData is the original data that we feed the table
 * @param {*} settings
 * @param {*} columns
 * sort the table asc or desc
 */
export const tableSorting = (data, actualData, settings, columns) => {
  // Calculate the start and end indexes based on the scroll position
  let startIndex = data.start;
  let endIndex = data.start + data.length;
  let searchValue = settings.oPreviousSearch.sSearch;
  let filteredData = actualData.filter(function (row) {
    let matchesSearch = false;
    for (let i = 0; i < settings.aoColumns.length; i++) {
      let column = settings.aoColumns[i];
      if (
        column.bSearchable &&
        row[column.data]
          .toString()
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1
      ) {
        matchesSearch = true;
        break;
      }
    }
    return matchesSearch;
  });

  // sort the filtered data based on the sorting column and direction
  let sortColumn = columns[data.order[0].column];
  if (Number.isFinite(sortColumn.orderData))
    sortColumn = columns[sortColumn.orderData];
  const columnName = sortColumn.data;
  if (sortColumn && columnName) {
    const sortDirection = data.order[0].dir;
    if (sortColumn.type === "num") {
      if (sortDirection === "asc")
        filteredData.sort((item1, item2) =>
          item1[columnName] === defaultCellValue
            ? -1
            : item2[columnName] === defaultCellValue
              ? 1
              : item1[columnName] - item2[columnName]
        );
      else
        filteredData.sort((item1, item2) =>
          item2[columnName] === defaultCellValue
            ? -1
            : item1[columnName] === defaultCellValue
              ? 1
              : item2[columnName] - item1[columnName]
        );
    } else {
      if (sortDirection === "asc")
        filteredData.sort((item1, item2) =>
          item1[columnName] < item2[columnName] ? -1 : 1
        );
      else
        filteredData.sort((item1, item2) =>
          item2[columnName] < item1[columnName] ? -1 : 1
        );
    }
  }

  // Slice the data array based on the start and end indexes
  let newData = filteredData.slice(startIndex, endIndex);
  return { newData, filteredCount: filteredData.length };
};

//To get post-login notifications
export const getPostLoginNotifications = async setPostLoginNotifications => {
  if (!isReportsView(location)) {
    try {
      let res = await apiService.getPostLoginNotifications();
      if (res && res.data && res.status === 200) {
        setPostLoginNotifications(res.data.data);
      }
    } catch (err) {
      console.log("Error while calling getPostLoginNotifications API", err);
      createGenericErrorObject(err);
    }
  }
};

//To get report timerange text using time_range response
export const getReportTrendText = data => {
  switch (true) {
    case data === "daily":
      return " previous day";
    case data === "weekly":
      return " previous week";
    case data === "monthly":
      return " previous month";
    default:
      return " previous day";
  }
};

//Get time frame input in terms of days to send payload in report site api call
export const getReportTimeRangeDate = data => {
  switch (true) {
    case data === "daily":
      return "24h";
    case data === "weekly":
      return "7d";
    case data === "monthly":
      return "30d";
    default:
      return "24h";
  }
};

//Get time period in hours
export const getReportTimePeriod = data => {
  switch (true) {
    case data === "daily":
      return "24";
    case data === "weekly":
      return "168";
    case data === "monthly":
      return "720";
    default:
      return "24";
  }
};

/**
 *   check if flags(i.e currentOverlayName, currentOverlay, role) in local storage are valid
 *   and if not valid redirect to accounts page
 */
export const isValidFlagsInLocalStorage = () => {
  const featureObject = JSON.parse(getLocalStorageFlag("availableFeatures"));

  if (_.isEmpty(featureObject) && featureObject instanceof Object) {
    return true;
  }

  if (
    !isAccountsPage() &&
    location.pathname !== "/" &&
    !location.pathname.includes("reporting")
  ) {
    const overlayName = localStorage.getItem("currentOverlayName");
    const currentOverlay = localStorage.getItem("currentOverlay");
    const roles = localStorage.getItem("roles");

    const isOverlayNameValid =
      overlayName && overlayName !== "null" && overlayName !== "";
    const isCurrentOverlayValid =
      currentOverlay && currentOverlay !== "null" && currentOverlay !== "";
    const isRolesValid = roles && roles !== "null" && roles !== "";
    //if all confition are valid return true else false, meaning some flags are not valid
    return isOverlayNameValid && isCurrentOverlayValid && isRolesValid;
  }

  return true;
};

// This method scrolls to the top of the page
export const scrollToTop = () => {
  if (typeof document.documentElement.scrollTo === "function")
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  else document.documentElement.scrollTop = 0;
};

//when circuit name is clicked redirect to circuit 360
export const circuitNameClick = (history, rowData) => {
  if (rowData.circuit_display_name) {
    sessionStorage.setItem(
      "circuit",
      JSON.stringify({
        ...rowData,
        overlay: localStorage.getItem("currentOverlay")
      })
    );
    history.push(`${BASE_URL}/circuits/${rowData.circuit_display_name.split(":").join("~")}`);
  }
};

export const enableTunnelSelection = tunnelInfo => {
  let enableSelection =
    tunnelInfo.tunnel_name &&
    !tunnelInfo.tunnel_name.toUpperCase()?.includes("DIA") &&
    !tunnelInfo.tunnel_name.toUpperCase()?.includes("SIG") &&
    tunnelInfo.vqoe_score !== "-";
  return enableSelection;
};

/**
 * Description: This method returns info icon with tooltip
 * @param {string} content is the tooltip text
 */
export const getInfoIcon = content => `
  <hbr-tooltip hoist content="${content}">
    <hbr-icon name="info" sentiment="neutral"></hbr-icon>
  </hbr-tooltip>
`;

// Format legend data
export const formatLegendData = (legend, timeFilterType) => {
  const { theme, labels } = legend;
  const _colors = getThemeColors(theme);
  return labels.map((label, index) => {
    const color = _colors[index];
    const formatLabel =
      label === i18nMessageBundle.commonUtilPrevious && timeFilterConfig[timeFilterType]
        ? `${i18nMessageBundle.commonUtilPrevious} ${timeFilterConfig[timeFilterType].legendText.toLowerCase()}`
        : label;
    return { label: formatLabel, color };
  });
};

/// date in timestamp
export const compareAndMatchELT = (data, timeFilter, entry_time = "date") => {
  if (data && data.length > 0) {
    let lastRecord = data[data.length - 1];
    let matchedData = {}

    if (
      lastRecord[entry_time] < timeFilter.current_period[1] &&
      lastRecord[entry_time] ==
      getStartOfHour(timeFilter.current_period[1].valueOf())
    ) {
      Object.keys(lastRecord).forEach((key) => {
        matchedData[key] = key == entry_time ? timeFilter.current_period[1] : lastRecord[key]
      })
      if (lastRecord.tooltipData) {
        matchedData["tooltipData"][entry_time] = displayDateTime(matchedData[entry_time])
      }
      data.push(matchedData)
    } else {
      if (
        lastRecord[entry_time] < timeFilter.current_period[1] &&
        lastRecord[entry_time] !=
        timeFilter.current_period[1] &&
        getEndOfHour(lastRecord[entry_time]).valueOf() != lastRecord[entry_time]
      ) {
        Object.keys(lastRecord).forEach((key) => {
          matchedData[key] = key == entry_time ? new Date(getEndOfHour(lastRecord[key])).valueOf() : lastRecord[key]
        })
        if (lastRecord.tooltipData) {
          matchedData["tooltipData"][entry_time] = displayDateTime(matchedData[entry_time])
        }
        data.push(matchedData)
      }
    }
  }
  return data;
};

/// date in UTC
export const compareAndMatchCircuitsELT = (data, timeFilter, entry_time) => {
  if (data && data.length > 0) {
    let lastRecord = data[data.length - 1];
    let matchedData = {}
    let endTime = timeFilter.current_period ? timeFilter.current_period[1] : timeFilter[1]
    if (
      lastRecord[entry_time] < timeFilter.current_period[1] &&
      lastRecord[entry_time].valueOf() ==
      getStartOfHour(endTime.valueOf())
    ) {
      Object.keys(lastRecord).forEach((key) => {
        matchedData[key] = key == entry_time ? endTime : lastRecord[key]
      })
      data.push(matchedData)
    } else {
      if (
        lastRecord[entry_time] < timeFilter.current_period[1] &&
        lastRecord[entry_time] !=
        endTime &&
        getEndOfHour(lastRecord[entry_time]).valueOf() != lastRecord[entry_time]
      ) {
        Object.keys(lastRecord).forEach((key) => {
          matchedData[key] = key == entry_time ? getEndOfHour(lastRecord[key]).valueOf() : lastRecord[key]
        })
        data.push(matchedData)
      } else {
        if (
          lastRecord[entry_time].valueOf() > timeFilter.current_period[1]
        ) {
          lastRecord[entry_time] = timeFilter.current_period[1];
        }
      }
    }
  }
  return data;
};

export const compareAndMatchDateTime = (data, globalFilter, entry_time) => {
  if (data && data.length > 0) {
    let lastRecord = data[data.length - 1];
    let matchedData = {}
    let duration = moment.duration(moment(globalFilter.timeFilter.current_period[1]).diff(moment(lastRecord[entry_time]).local().valueOf()));
    let hours = duration.asSeconds();
    if (
      hours > 0 &&
      getStartOfHour(lastRecord[entry_time]).local().valueOf() ==
      getStartOfHour(globalFilter.timeFilter.current_period[1]).local().valueOf()
    ) {

      Object.keys(lastRecord).forEach((key) => {
        matchedData[key] = key == entry_time ? moment(globalFilter.timeFilter.current_period[1]).utc().valueOf() : JSON.parse(JSON.stringify(lastRecord[key]))
      })
      matchedData["tooltipData"][entry_time] = displayDateTime(moment(globalFilter.timeFilter.current_period[1]).utc().valueOf())
      data.push(matchedData)
    } else {
      if (
        hours > 0 &&
        lastRecord[entry_time] !=
        globalFilter.timeFilter.current_period[1] &&
        getEndOfHour(lastRecord[entry_time]).local().valueOf() != lastRecord[entry_time]
      ) {
        Object.keys(lastRecord).forEach((key) => {
          matchedData[key] = key == entry_time ? new Date(getEndOfHour(lastRecord[key])).valueOf() : JSON.parse(JSON.stringify(lastRecord[key]));
        })
        matchedData["tooltipData"][entry_time] = displayDateTime(matchedData[entry_time])
        data.push(matchedData)
      }
    }
  }
  return data;
};

/**
 *
 * @param {*} props  is an object with properties such as app_data_with_family_sla, and appName
 */
export const getMapSummaryApp360 = (props) => {
  let summaryData = [];
  if (props.isReporting && props?.globalFilter.app_data_with_family_sla) {
    const filteredData = props?.globalFilter.app_data_with_family_sla.filter(
      row => row.application === props.match.params.appName
    );
    if (filteredData && filteredData?.length > 0) {
      const sitesCount =
        filteredData[0].bad_count +
        filteredData[0].fair_count +
        filteredData[0].good_count +
        filteredData[0].gray_count;

      summaryData = {
        total_sites: sitesCount,
        high: { count: filteredData[0].good_count },
        fair: { count: filteredData[0].fair_count },
        low: { count: filteredData[0].bad_count },
        gray: { count: filteredData[0].gray_count }
      }
    }
  }

  return summaryData;
}

/**
 *
 * @param {*} globalFilter
 * This function validate if custom filter has start and end date
 * and if there is none than return fixed time range like 24h or 7d value
 */
export const validateTimePeriodSelected = (globalFilter) => {

  //set default time period to 12h when user is on accounts page
  if (isAccountsPage()) return "12h";

  let isCustomRangeValid = false;

  if (globalFilter.selectedCustomTime?.range?.start && globalFilter.selectedCustomTime?.range?.end) {
    isCustomRangeValid = !isCustomRangeValid;
  }

  const storedTimePeriod = getStoredTimePeriod();
  return timeFilterTypes[isCustomRangeValid ? globalFilter.timePeriodSelected : storedTimePeriod];
}

export const validateTimePeriodSelectedSidebar = (globalFilter) => {
  let isCustomRangeValid = false;
  if (globalFilter.sideBarTimeFilter.current_period[0] && globalFilter.sideBarTimeFilter.current_period[1]) {
    isCustomRangeValid = !isCustomRangeValid;
  }
  const storedTimePeriod = getLocalStorageFlag("timePeriodSelected");
  return timeFilterTypes[isCustomRangeValid ? globalFilter.sideBarTimeFilter.timePeriodSelected : storedTimePeriod];
}

/**
 * Description: Searches the entry data at a given time
 * @param {object[]} arr is the chart data
 * @param {number} start is the start index
 * @param {number} end is the end index
 * @param {number} timestamp is the time to find the entry
 * @returns entry data at a given time
 */

export const binarySearchChartData = (
  arr,
  start,
  end,
  timestamp,
  interval = 3600000
) => {
  const diff = end - start;
  let mid = start + Math.floor(diff / 2);
  if (arr[mid].date === timestamp) return arr[mid];
  else if (diff === 1) {
    if (arr[end].date === timestamp) return arr[end];
    else {
      if (
        timestamp < arr[end].date
        && timestamp > arr[start].date
        && arr[end].date <= arr[start].date + interval
      )
        return arr[start];
      return null;
    }
  }
  else if (arr[mid].date > timestamp)
    return binarySearchChartData(arr, start, mid, timestamp, interval);
  if (arr[mid].date < timestamp)
    return binarySearchChartData(arr, mid, end, timestamp, interval);
};

// To generate random UUID for Geo Map sites
export const randomUUID = () => {
  return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

/**
 * get the time period from local storage
 */
export const getStoredTimePeriod = () => {
  const isUIconvergence = getCookie("cl-analytics");

  let defaultTimePeriodSelected = isUIconvergence ? "1" : "12";

  const vMangeRedirectURI = getCookie("cl-vManageURI");

  if (vMangeRedirectURI && isUIconvergence) {
    const analyticsCookies = getCookie("cl-analytics") ?
      JSON.parse(getCookie("cl-analytics")) : {};
    const timePeriod = analyticsCookies.timePeriodSelected ?
      analyticsCookies.timePeriodSelected
      : "1";
    defaultTimePeriodSelected = timePeriod;
  } else {
    if (Number.isInteger(parseInt(getLocalStorageFlag("timePeriodSelected")))) {
      defaultTimePeriodSelected = getLocalStorageFlag("timePeriodSelected")
    }
  }
  return defaultTimePeriodSelected;
}

/**
 * get the time period from local storage
 */
export const getStoredPath = () => {
  let currentPath = "overview";

  const vMangeRedirectURI = getCookie("cl-vManageURI");
  const launchCookie = getCookie("launch-dashboard");
  const isUIconvergence = getCookie("cl-analytics");

  if (vMangeRedirectURI && isUIconvergence) {
    const analyticsCookies = JSON.parse(getCookie("cl-analytics"));
    currentPath = analyticsCookies.currentPath ?
      analyticsCookies.currentPath.toLowerCase()
      : "overview";
  } else if (launchCookie) {
    currentPath = launchCookie.toLowerCase();
  } else {
    currentPath = getLocalStorageFlag("currentPath").toLowerCase();
  }

  //take care of the use case where path is i.e. /v4/sites
  if (currentPath.includes("/")) {
    const slash = currentPath.split("/");
    currentPath = slash[slash.length - 1]
  }

  return currentPath;
}

/**
 * get current site from local storage
 */
export const getStoredCurrentSite = () => {
  let site = -1;

  const isUIconvergence = getCookie("cl-analytics");

  if (isUIconvergence) {
    const analyticsCookies = getCookie("cl-analytics") ?
      JSON.parse(getCookie("cl-analytics")) : {};
    const currentSite = parseInt(analyticsCookies.currentSite);

    site = Number.isInteger(currentSite) ?
      currentSite : null;

  } else {
    const currentSite = parseInt(getLocalStorageFlag("currentSite"));
    site = Number.isInteger(currentSite) ?
      currentSite :
      -1
  }
  return site;
}

/**
 * get the mode value for tab navigation from local storage
 */
export const getConvergedCookie = (type) => {
  let cookie = "";

  const isCookiePresent = getCookie("cl-analytics");

  if (isCookiePresent) {
    const analyticsCookies = getCookie("cl-analytics") ?
      JSON.parse(getCookie("cl-analytics")) : {};
    cookie = analyticsCookies[type];
  }

  return cookie;
}

/**
 * get the fabric mode value for tab navigation from local storage
 */
export const getStoredTabFabricMode = () => {
  let isNonFabricMode = false;

  const isUIconvergence = getCookie("cl-analytics");

  if (isUIconvergence) {
    const analyticsCookies = getCookie("cl-analytics") ?
      JSON.parse(getCookie("cl-analytics")) : {};
    isNonFabricMode = analyticsCookies.isNonFabricMode === true;
  }

  return isNonFabricMode;
}

/**
 * updated the cl-analytics cookie with new value and key
 */
export const updateCookie = (cookieKey, cookieValue) => {
  const isUIconvergence = getCookie("cl-analytics");
  const originalCookie = {
    "timePeriodSelected": "1",
    "currentSite": null,
    "mode": "singleTenant",
    "isNonFabricMode": false,
    "tenancyMode": "",
    "tenantId": ""
  };

  if (isUIconvergence) {
    if (getCookie("cl-analytics")) {
      const originalCookie = JSON.parse(getCookie("cl-analytics"));
      originalCookie[cookieKey] = cookieValue;
      deleteCookie("cl-analytics")
      document.cookie = `cl-analytics=${JSON.stringify(originalCookie)}; path=/; SameSite=None; Secure`;
    } else {
      deleteCookie("cl-analytics")
      originalCookie[cookieKey] = cookieValue;
      document.cookie = `cl-analytics=${JSON.stringify(originalCookie)}; path=/; SameSite=None; Secure`;
    }
  }
}

//key and value style
export const getKeyValueStyle = (key, value) => {
  return (
    <div className="key-value">
      <span className="key">{key}</span>
      <span className="value">{value ? value : defaultCellValue}</span>
    </div>
  )
}
export const validateTimePeriodSelectedSidebarPayload = (globalFilter) => {
  let isCustomRangeValid = false;
  if (globalFilter.time_period.current_period[0] && globalFilter.time_period.current_period[1]) {
    isCustomRangeValid = !isCustomRangeValid;
  }
  const storedTimePeriod = getLocalStorageFlag("timePeriodSelected");
  return timeFilterTypesCustomV4[
    isCustomRangeValid ? globalFilter.timePeriodSelected : storedTimePeriod
  ];
}

/**
 * Description: Creates a CSV file with the given CSV content
 * @param {string} csvContent is the content of the CSV file
 * @param {string} title is the main part of the CSV file name
 */
export const downloadAsCSVFile = (csvContent, title = "Download") => {
  const fileName = title + "_" + displayDateTime(new Date(), "yyyy-MM-dd hh mm ss aa") + ".csv";
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
  saveAs(blob, fileName);
};

/**
 * Description: map the icons from api with existing icons in harbor library
 * @param: icon is a string name
 */
export const getIconName = (icon) => {
  switch (true) {
    case icon === "computer": return "nav-monitor";
    case icon === "settings": return "nav-configuration";
    case icon === "build": return "nav-tools";
    case icon === "business_center": return "nav-maintenance";
    case icon === "people": return "nav-admin";
    case icon === "workflows": return "nav-workflows";
    case icon === "reports": return "nav-reports";
    case icon === "insert_chart": return "nav-insight";
    case icon === "explore": return "nav-explore";
    default: return icon;
  }
}

export const getTimeInterval = (timeFrame) => {
  return timeFilters5m.includes(timeFrame) ? timeMS.min5 : timeMS.hour1;
};

export const getIsHourlyData = (timeFrame) => {
  return !timeFilters5m.includes(timeFrame);
}

export const getTrendInitialLoad = (globalFilter, initialLoad) => {
  return ((initialLoad && globalFilter.sideBarTimeFilter.timePeriodSelected == globalFilter.timePeriodSelected) ||
    !initialLoad || globalFilter.sideBarTimeFilter.timePeriodSelected === undefined)
}
