import i18nMessageBundle from "amdi18n-loader!./nls/i18n";
import { statusColors } from "./colors";

export const PAGE = {
  SCOPE: "setup-report-scope",
  FILETYPE: "setup-filetype",
  SCHEDULE_TIME: "setup-report-scheduler",
  DELIVERY: "setup-report-email-delivery",
  SUMMARY: "setup-report-summary",
  REPORTS: "reports",
  REPORT: "report"
};

export const getPageFromStep = {
  1: PAGE.SCOPE,
  2: PAGE.FILETYPE,
  3: PAGE.SCHEDULE_TIME,
  4: PAGE.DELIVERY,
  5: PAGE.SUMMARY,
  6: PAGE.REPORTS
};

export const SITE = {
  CUSTOM: "custom-sites",
  CUSTOM_SITES: i18nMessageBundle.enumsCustomSites,
  ALL: "all-sites",
  ALL_SITES: i18nMessageBundle.enumsAllSites,
  TYPE: "site"
};

export const APP = {
  CUSTOM: "custom-application",
  CUSTOM_APP: i18nMessageBundle.enumsCustomSites,
  ALL: "all-application",
  ALL_APPS: i18nMessageBundle.enumsAllApplications
};

export const BannerTypes = {
  LOADING: "loading",
  ERROR: "error"
};

export const filter = {
  apps: {
    table_filter: { vqoe_status: ["good", "fair", "poor"] },
    sort: { vqoe_score: "asc" },
    size: 5,
    offset: 0
  },
  sites: {
    sort: { up_percent: "asc" },
    size: 5,
    offset: 0
  }
};

export const usageFilter = {
  sort: {
    usage: "desc"
  },
  offset: 0,
  size: 20
};

export const filterAppExperience = {
  ...usageFilter,
  table_filter: {
    vqoe_status: ["poor", "fair", "good"]
  }
};

export const FORMAT_PAGE_NAME = {
  EXECUTIVE: "executive_summary",
  ALL_APPLICATIONS: "application_summary",
  ALL_SITES: "site_summary",
  APP_360: "app_360",
  SITE_360: "site_360"
};

export const FORMAT_CSV_PAGE_NAME = {
  ALL_APPLICATIONS: "all_apps_csv",
  ALL_SITES: "all_sites_csv",
  APP_360: "app360_csv"
};

export const PAGE_NAME = {
  EXECUTIVE: "Executive Summary",
  ALL_APPLICATIONS: "Application Summary",
  ALL_SITES: "All Sites",
  APP_360: "App 360",
  SITE_360: "Site 360"
};

export const REPEATS = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTLY: "monthly"
};

export const SCHEDULER = {
  RECURRING: "recurring",
  NOW: "now"
};

export const TEMPLATE_NAME = {
  [PAGE_NAME.EXECUTIVE]: i18nMessageBundle.commonUtilTemplatesSummary,
  [PAGE_NAME.ALL_APPLICATIONS]: i18nMessageBundle.commonUtilTemplatesApps,
  [PAGE_NAME.ALL_SITES]: i18nMessageBundle.commonUtilTemplatesSites,
  [PAGE_NAME.APP_360]: i18nMessageBundle.commonUtilTemplatesApp360,
  [PAGE_NAME.SITE_360]: i18nMessageBundle.commonUtilTemplatesSite360
};

export const regexEmail = /^(([^<>()[\]\\.,;|:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const regexName = /^[0-9A-Za-z_.-]+$/;

export const initGlobalState = {
  step: 1,
  isNextDisabled: true,
  repeatTime: "",
  repeatOn: 1
};

// Regex can validate domain names - Host name with range from (3-61)
//eslint-disable-next-line
export const regexDomains = /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/;

export const SWITCH = {
  OVERVIEW: {
    ACTION: "SET_OVERVIEW",
    LABEL: i18nMessageBundle.commonUtilOverview
  },
  TOP_SITES: { ACTION: "SET_TOP_SITES" }
};

export const TOGGLER = {
  MAP: "map",
  LIST: "list",
  CARD: "cards",
  CHART: "chart",
  AVAILABILITY: "availability",
  RECOMMENDATION: "recommendation",
  SDWAN: "Sdwan",
  TALOS: "Talos",
  TALOSGLOBALTHREATS: "talosGlobalThreats",
  TALOSACCESSTHREATS: "talosAccessThreats"
};

export const EXPERIENCE_COLORS = {
  GOOD: statusColors.good, //OK
  FAIR: statusColors.fair, //WAITING
  POOR: statusColors.danger, //DOWN
  NA: statusColors.gray,
  GRAY: statusColors.gray,
  UNKNOWN: statusColors.gray, //DOWN
  READY: statusColors.good,
  DELETED: statusColors.black,
  NOCHANGE: statusColors.gray,
  SDWANGOOD: statusColors.sdwanGood,
  SDWANPOOR: statusColors.sdwanPoor,
  TALOSPOOR: statusColors.talosPoor
};
export const ACTIONSTATE = {
  NON_APPLIED: "NON_APPLIED",
  APPLY_REQUEST: "APPLY_REQUEST",
  APPLIED: "APPLIED",
  REVERT_REQUEST: "REVERT_REQUEST",
  DELETED: "DELETED",
  STALE: "STALE",
  READY: "READY",
  NOCHANGE: "NOCHANGE",
  ALL: "ALL",
  APPLY: "Apply",
  REVERT: "Revert"
};

export const CUSTOM_MAKER = {
  CIRCLE: "CIRCLE",
  TRAY: "TRAY"
};

export const initialFilterArray = ["Low", "Fair", "High", "Unknown"];

export const siteFilter = {
  sort: {
    site_name: "asc"
  },
  offset: 0,
  size: 50
};
//i.e.  "red" is used to define the class in the style.less=> qoe-red or qoe-black className
export const COLOR = {
  RED: "red",
  BLUE: "blue",
  GREEN: "green",
  BLACK: "black",
  GRAY: "gray",
  FAIR: "fair",
  GOOD: "good",
  POOR: "poor",
  UNKNOWN: "unknown"
};

/**
 * Beta flag tracking wiki https://wiki.cisco.com/display/VANALYTICS/Beta+Feature+Flags
 */
export const FEATURE_CONFIG = {
  ALTO: {
    isBeta: new Date() < new Date(2023, 2, 15), // Wednesday, March 15, 2023
    betaMessage: "Beta Version.",
    startDate: new Date(),
    endDate: new Date(2023, 2, 15)
  },
  CIRCUITS: {
    isBeta: new Date() < new Date(2023, 4, 16), // the month is 0-indexed
    betaMessage: "Beta Version.",
    startDate: new Date(),
    endDate: new Date(2023, 4, 16)
  },
  BF: {
    isBeta: new Date() < new Date(2024, 6, 29),
    betaMessage: "Beta Version.",
    startDate: new Date(),
    endDate: new Date(2024, 6, 29)
  },
  AD: {
    isBeta: new Date() < new Date(2024, 3, 31),
    betaMessage: "Beta Version.",
    startDate: new Date(),
    endDate: new Date(2024, 3, 31)
  },
  TIMEFILTER: {
    isBeta: new Date() < new Date(2024, 11, 6),
    betaMessage: "Beta Version.",
    startDate: new Date(),
    endDate: new Date(2024, 11, 6)
  },
  LOGS: {
    isBeta: new Date() < new Date(2025, 1, 1),
    betaMessage: "Beta Version.",
    startDate: new Date(),
    endDate: new Date(2025, 1, 1)
  }
};

export const APPCLASS = {
  VOICE: "voice",
  WEBEX: "webex",
  OFFICE365: "office365",
  SALESFORCE: "salesforce",
  GOOGLE: "google-workspace",
  GOTOMEETING: "gotomeeting"
};

export const defaultTextValue = "--";

export const defaultCellValue = "-";

export const customTimeFilterText = "Custom";

// '●' - circle character's character code is 9679
export const charCircle = String.fromCharCode(9679);

export const AVAILABILITY_STATUS = {
  ALL: "all",
  HIGH: "high",
  FAIR: "fair",
  LOW: "low",
  NA: "unknown"
};

export const timeFilterTypes = {
  "1": "1h",
  "3": "3h",
  "6": "6h",
  "12": "12h",
  "24": "24h",
  "168": "7d",
  "720": "30d",
  "Custom": "CUSTOM",
  "daily": "24h",
  "weekly": "7d",
  "monthly": "30d"
};

export const fields = [
  "site_id",
  "application",
  "vqoe_status",
  "vqoe_score",
  "vqoe_change",
  "usage",
  "packet_loss",
  "latency",
  "jitter",
  "site_name",
  "city",
  "latitude",
  "longitude",
  "application_class"
];

export const tLocState = {
  adminDown: "admin-down",
  unknown: "unknown",
  operDown: "oper-down",
  operUp: "oper-up"
};

export const webexGroup = "webex-group";

export const timeFilterTypesCustomV4 = {
  "1": "1h",
  "3": "3h",
  "6": "6h",
  "12": "12h",
  "24": "24h",
  "168": "7d",
  "720": "30d",
  "Custom": "custom",
  "daily": "24h",
  "weekly": "7d",
  "monthly": "30d"
};

// IEC standard decimal byte units
// reference: https://www.iec.ch/prefixes-binary-multiples
// unit reference: https://www.electropedia.org/iev/iev.nsf/display?openform&ievref=171-02-13
export const byteDecimalUnits =
  ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

// protocol number and keyword pair
export const protocols = {
  0: "HOPOPT",
  1: "ICMP",
  2: "IGMP",
  3: "GGP",
  4: "IPv4",
  5: "ST",
  6: "TCP",
  7: "CBT",
  8: "EGP",
  9: "IGP",
  10: "BBN-RCC-MON",
  11: "NVP-II",
  12: "PUP",
  13: "ARGUS",
  14: "EMCON",
  15: "XNET",
  16: "CHAOS",
  17: "UDP",
  18: "MUX",
  19: "DCN-MEAS",
  20: "HMP",
  21: "PRM",
  22: "XNS-IDP",
  23: "TRUNK-1",
  24: "TRUNK-2",
  25: "LEAF-1",
  26: "LEAF-2",
  27: "RDP",
  28: "IRTP",
  29: "ISO-TP4",
  30: "NETBLT",
  31: "MFE-NSP",
  32: "MERIT-INP",
  33: "DCCP",
  34: "3PC",
  35: "IDPR",
  36: "XTP",
  37: "DDP",
  38: "IDPR-CMTP",
  39: "TP++",
  40: "IL",
  41: "IPv6",
  42: "SDRP",
  43: "IPv6-Route",
  44: "IPv6-Frag",
  45: "IDRP",
  46: "RSVP",
  47: "GRE",
  48: "DSR",
  49: "BNA",
  50: "ESP",
  51: "AH",
  52: "I-NLSP",
  53: "SWIPE",
  54: "NARP",
  55: "MOBILE",
  56: "TLSP",
  57: "SKIP",
  58: "IPv6-ICMP",
  59: "IPv6-NoNxt",
  60: "IPv6-Opts",
  61: "Internal",
  62: "CFTP",
  63: "Local",
  64: "SAT-EXPAK",
  65: "KRYPTOLAN",
  66: "RVD",
  67: "IPPC",
  68: "Distributed File System",
  69: "SAT-MON",
  70: "VISA",
  71: "IPCV",
  72: "CPNX",
  73: "CPHB",
  74: "WSN",
  75: "PVP",
  76: "BR-SAT-MON",
  77: "SUN-ND",
  78: "WB-MON",
  79: "WB-EXPAK",
  80: "ISO-IP",
  81: "VMTP",
  82: "SECURE-VMTP",
  83: "VINES",
  84: "TTP / IPTM",
  85: "NSFNET-IGP",
  86: "DGP",
  87: "TCF",
  88: "EIGRP",
  89: "OSPFIGP",
  90: "Sprite-RPC",
  91: "LARP",
  92: "MTP",
  93: "AX.25",
  94: "IPIP",
  95: "MICP",
  96: "SCC-SP",
  97: "ETHERIP",
  98: "ENCAP",
  99: "Private",
  100: "GMTP",
  101: "IFMP",
  102: "PNNI",
  103: "PIM",
  104: "ARIS",
  105: "SCPS",
  106: "QNX",
  107: "A/N",
  108: "IPComp",
  109: "SNP",
  110: "Compaq-Peer",
  111: "IPX-in-IP",
  112: "VRRP",
  113: "PGM",
  114: "0-hop",
  115: "L2TP",
  116: "DDX",
  117: "IATP",
  118: "STP",
  119: "SRP",
  120: "UTI",
  121: "SMP",
  122: "SM",
  123: "PTP",
  124: "ISIS over IPv4",
  125: "FIRE",
  126: "CRTP",
  127: "CRUDP",
  128: "SSCOPMCE",
  129: "IPLT",
  130: "SPS",
  131: "PIPE",
  132: "SCTP",
  133: "FC",
  134: "RSVP-E2E-IGNORE",
  135: "Mobility Header",
  136: "UDPLite",
  137: "MPLS-in-IP",
  138: "manet",
  139: "HIP",
  140: "Shim6",
  141: "WESP",
  142: "ROHC",
  143: "Ethernet",
  144: "AGGFRAG",
  145: "Unassigned",
  146: "Unassigned",
  147: "Unassigned",
  148: "Unassigned",
  149: "Unassigned",
  150: "Unassigned",
  151: "Unassigned",
  152: "Unassigned",
  153: "Unassigned",
  154: "Unassigned",
  155: "Unassigned",
  156: "Unassigned",
  157: "Unassigned",
  158: "Unassigned",
  159: "Unassigned",
  160: "Unassigned",
  161: "Unassigned",
  162: "Unassigned",
  163: "Unassigned",
  164: "Unassigned",
  165: "Unassigned",
  166: "Unassigned",
  167: "Unassigned",
  168: "Unassigned",
  169: "Unassigned",
  170: "Unassigned",
  171: "Unassigned",
  172: "Unassigned",
  173: "Unassigned",
  174: "Unassigned",
  175: "Unassigned",
  176: "Unassigned",
  177: "Unassigned",
  178: "Unassigned",
  179: "Unassigned",
  180: "Unassigned",
  181: "Unassigned",
  182: "Unassigned",
  183: "Unassigned",
  184: "Unassigned",
  185: "Unassigned",
  186: "Unassigned",
  187: "Unassigned",
  188: "Unassigned",
  189: "Unassigned",
  190: "Unassigned",
  191: "Unassigned",
  192: "Unassigned",
  193: "Unassigned",
  194: "Unassigned",
  195: "Unassigned",
  196: "Unassigned",
  197: "Unassigned",
  198: "Unassigned",
  199: "Unassigned",
  200: "Unassigned",
  201: "Unassigned",
  202: "Unassigned",
  203: "Unassigned",
  204: "Unassigned",
  205: "Unassigned",
  206: "Unassigned",
  207: "Unassigned",
  208: "Unassigned",
  209: "Unassigned",
  210: "Unassigned",
  211: "Unassigned",
  212: "Unassigned",
  213: "Unassigned",
  214: "Unassigned",
  215: "Unassigned",
  216: "Unassigned",
  217: "Unassigned",
  218: "Unassigned",
  219: "Unassigned",
  220: "Unassigned",
  221: "Unassigned",
  222: "Unassigned",
  223: "Unassigned",
  224: "Unassigned",
  225: "Unassigned",
  226: "Unassigned",
  227: "Unassigned",
  228: "Unassigned",
  229: "Unassigned",
  230: "Unassigned",
  231: "Unassigned",
  232: "Unassigned",
  233: "Unassigned",
  234: "Unassigned",
  235: "Unassigned",
  236: "Unassigned",
  237: "Unassigned",
  238: "Unassigned",
  239: "Unassigned",
  240: "Unassigned",
  241: "Unassigned",
  242: "Unassigned",
  243: "Unassigned",
  244: "Unassigned",
  245: "Unassigned",
  246: "Unassigned",
  247: "Unassigned",
  248: "Unassigned",
  249: "Unassigned",
  250: "Unassigned",
  251: "Unassigned",
  252: "Unassigned",
  253: "Testing",
  254: "Testing",
  255: "Reserved"
};

export const timeFilters5m = [
  timeFilterTypesCustomV4["1"],
  timeFilterTypesCustomV4["3"],
  timeFilterTypesCustomV4["6"]
];

export const timeMS = { min5: 300000, hour1: 3600000 };
