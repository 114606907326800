import { colors } from "../colors";

// Get donut internal text (total count and title)
export const getDonutText = (title, totals) => {
  return [
    {
      position: "35%",
      size: 32,
      text: sumValues(totals),
      color: colors.gray15
    },
    {
      position: "55%",
      size: 12,
      text: title,
      color: colors.gray15
    }
  ];
};

// Return sum of an array of values
export const sumValues = vals => {
  return Object.values(vals).reduce((a, b) => a + b);
};

// Get donut data (map text from config to values)
export const getDonutData = (text, totals) => {
  const total = sumValues(totals);
  return Object.keys(text).map(key => {
    const { title, tooltip } = text[key];
    const percent = total > 0 ? (totals[key] / total) * 100 : 0;
    return {
      title,
      tooltip,
      value: totals[key],
      percent: percent.toFixed(1)
    };
  });
};
