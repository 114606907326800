import { getQoeScore, getAvailabilityScore } from "./common";

const defaultColor = "var(--hbr-color-neutral-text, #23282E)";

export const colors = {
  amber60: "#CC8604", // warning sentiment
  amber55: "#BD7202",
  yellow85: "#F2D268",
  yellow70: "#E0A419",
  blue70: "#7CADF7",
  blue50: "#2774D9", // info sentiment
  blue45: "#1D69CC", // interact sentiment
  cyan60: "#04A4B0",
  cyan40: "#006773",
  gray100: "#FFFFFF", // white
  gray90: "#E1E4E8",
  gray85: "#D0D4D9",
  gray80: "#C1C6CC",
  gray70: "#a7adb5",
  gray60: "#889099", // neutral sentiment
  gray50: "#6f7680",
  gray45: "#656C75",
  gray40: "#596069",
  gray35: "#464c54",
  gray15: "#23282E", // neutral text
  gray0: "#000000", // black
  green55: "#45991F", // success sentiment
  green70: "#a1db84",
  green85: "#d1f5bf",
  indigo60: "#7D8AFF",
  lavendar40: "#545C8A",
  lime60: "#6BA32A",
  orange40: "#AD3907",
  pink60: "#E85FC6",
  purple40: "#753BCC",
  red50: "#D93843",
  red45: "#CC2D37", // danger sentiment
  red70: "#f0b3aa",
  red60: "#D93843",
  rose60: "#F2638C",
  rose40: "#B02863",
  grayStrongBase: "#373c42",
  grayTransparent: "#656C75CC",
  grayTalosMap : "#ABB7C0",
  teal60: "#04A4B0",
  limegreen60: "#7DA11B"
};

// all colors pass a 3:1 contrast ratio against white.
export const chartPrimaryColors = [
  colors.indigo60, // #7D8AFF
  colors.rose40, // #B02863
  colors.rose60, // #F2638C
  colors.purple40, // #753BCC
  colors.lime60, // #6BA32A
  colors.orange40, // #AD3907
  colors.cyan60, // #04A4B0
  colors.cyan40, // #006773
  colors.pink60, // #E85FC6
  colors.lavendar40 // #545C8A
];

export const sentimentColors = {
  neutral: colors.gray60,
  interact: colors.blue45,
  success: colors.green55,
  warning: colors.amber55,
  danger: colors.red45,
  info: colors.blue50
};

export const statusColors = {
  good: colors.green55,
  fair: colors.amber60,
  poor: colors.red45,
  gray: colors.gray60,
  black: colors.gray0,
  danger: colors.red45,
  sdwanGood:colors.indigo60,
  sdwanPoor:colors.rose40,
  talosPoor: colors.grayTransparent
};

export const meanQuality = [
  "#81A68E", // color for 91%
  "#8C888B", // color for 81%
  "#8C8788", // color for 73%
  "#8C7479", // color for 64%
  "#A67777", // color for 51%
  "#A68686", // color for 42%
  "#8C4F4F", // color for 32%
  "#A64141" // color for 10%
]

// Color harbor themes
export const hbrThemes = {
  quality: {
    good: "var(--hbr-color-success-border,#45991f)",
    fair: "var(--hbr-color-warning-border, #cc8604)",
    poor: "var(--hbr-color-danger, #CC2D37)",
    notAvailable: "var(--hbr-color-neutral-border, #889099)",
    gray: "var(--hbr-color-neutral-border, #889099)",
    unknown: "var(--hbr-color-neutral-border, #889099)"
  },
  overlay: {
    used: "var(--hbr-color-indigo-60, #7D8AFF)",
    previous: "var(--hbr-color-rose-40, #B02863)",
    available: "var(--hbr-color-gray-90, #E1E4E8)"
  },
  change: {
    positive: "var(--hbr-color-success-border,#45991f)",
    negative: "var(--hbr-color-danger, #CC2D37)"
  },
  changeQuality: {
    positive: "var(--hbr-color-success-border,#45991f)",
    negative: "var(--hbr-color-danger, #CC2D37)"
  }
};

// Get value color from theme
export const getColor = (value, theme) => {
  switch (theme) {
    case "qoe": {
      const qoeScore = getQoeScore(value);
      return getQualityColor(qoeScore);
    }
    case "availability": {
      const availScore = getAvailabilityScore(value);
      return getQualityColor(availScore);
    }
    case "change":
    case "changeQuality":
      return getChangeColor(value, theme);
    case "taloSdwan":
      return getTalosSdwanColor(value);
    default:
      return defaultColor;
  }
};

// Return color for sdwan color in map
export const getTalosSdwanColor = (value) => {  
  return value === 100 ? colors.indigo60 : colors.rose40;
}; 

// Return color for positive or negative value
export const getChangeColor = (value, type) => {
  const theme = hbrThemes[type];
  if (value === 0) return defaultColor;
  return value > -0.5 ? theme.positive : theme.negative;
};

// Return quality color if value is within quality range
export const getQualityColor = score => {
  const theme = hbrThemes.quality;
  return theme[score] ? theme[score] : theme.unknown;
};
// Return quality color if value is within quality range
export const getQualityColorHbr = score => {
  const theme = hbrThemes.quality;
  return theme[score] ? theme[score] : defaultColor;
};

// Return colors from a theme as array
export const getThemeColors = theme => {
  const colors = hbrThemes[theme];
  if (!colors) return [];
  return Object.values(colors);
};

export const anomalyColors = {
  loss: colors.teal60,
  latency: colors.purple40,
  jitter: colors.limegreen60
}
