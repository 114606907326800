import { useEffect, useRef } from "react";
import _ from "lodash";

//using func below to generate layout
//source https://github.com/react-grid-layout/react-grid-layout/blob/master/test/examples/0-showcase.jsx
export const generateLayout = () => {
  return _.map(_.range(0, 25), (item, i) => {
    var y = Math.ceil(Math.random() * 4) + 1;
    return {
      x: Math.round(Math.random() * 5) * 2,
      y: Math.floor(i / 6) * y,
      w: 2,
      h: y
    };
  });
};

export const setNavTab = (width, visibility) => {
  document.querySelector(".nav-organization-drop").style.visibility = visibility
  document.querySelector(".nonconverge").style.width = width;
  document.querySelector(".nav-divider").style.width = width;
}

/**
 * Description: This method displays toast
 * @param {string} msg is the message dispalyed in toast
 * @param {string} [sentiment] is the severity of toast
 * @param {number} [duration] is the duration of toast
 */

export const toast = (msg = "", sentiment = "success", duration = 5000, errorObject = "") => {
  let message = msg;

  if (errorObject) {
    message = `${errorObject.errorCode}: ${errorObject.message}`;
  }

  const toastEl = Object.assign(
    document.createElement("hbr-banner"),
    {
      duration: duration,
      sentiment: sentiment,
      closable: true,
      innerHTML: `
        <hbr-icon slot="icon" name="hbr-${sentiment}-toast"></hbr-icon>
        ${message}
      `
    }
  );
  document.body.appendChild(toastEl);
  if (toastEl.toast instanceof Function) toastEl.toast();
};

// This hook provides component mount state
export const useMount = () => {
  const mount = useRef({ initMount: true, mounted: true });
  useEffect(() => () => (mount.current.mounted = false), []);
  return mount.current;
};
