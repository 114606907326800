import { datadogRum } from "@datadog/browser-rum";
import moment from "moment";
import { checkCookieValidity } from "../utils/waniCommon";

const rumState = {};

export const initDatadogRum = (
  config
) => {
  const rum_config = {
    ...{
      "environment": "unknown",
      "region": "unknown",
      "enable_site_tracking": true,
      "enable_custom_time_range": true
    },
    ...config
  };

  const rumContext = {
    region: rum_config.region
  };
  const isCrossLaunch = rum_config.is_cross_launch || checkCookieValidity("cl-rec-apply-url");
  if (isCrossLaunch) {
    rumContext.isCrossLaunch = true;
  }

  datadogRum.init({
    applicationId: rum_config.application_id,
    clientToken: rum_config.client_token,
    site: "datadoghq.com",
    proxyUrl: window.location.origin + "/ddproxy",
    service: "v3-ui",
    env: rum_config.environment,
    version: "1.0.0",
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input"
  });

  datadogRum.setRumGlobalContext(rumContext);

  if (!rumState.enabled) {
    rumState.enableSiteTracking = rum_config.enable_site_tracking;
    rumState.enableCustomTimeRange = rum_config.enable_custom_time_range;
    rumState.enabled = true;
  }
  if (!Object.isFrozen(rumState)) {
    Object.freeze(rumState);
  }
};

export const setRumUser = userId => {
  if (rumState.enabled) {
    datadogRum.setUser({
      id: userId
    });
  }
};

export const removeRumUser = () => {
  if (rumState.enabled) {
    datadogRum.removeUser();
  }
};

export const updateRumSite = siteId => {
  if (rumState.enabled && rumState.enableSiteTracking) {
    if (typeof siteId !== "undefined" && siteId !== null) {
      siteId = parseInt(siteId);
      if (siteId === -1) {
        datadogRum.removeGlobalContextProperty("siteId");
      } else {
        datadogRum.setGlobalContextProperty("siteId", siteId);
      }
    }
  }
};

export const updateRumCustomTimeRange = customTimeRange => {
  /* This will update RUM custom time range property */
  if (rumState.enabled && rumState.enableCustomTimeRange) {
    if (typeof customTimeRange !== "undefined" && customTimeRange !== null) {
      const [startCustomTime, endCustomTime] = customTimeRange;
      const currentTime = moment();
      const startWeek = currentTime.diff(startCustomTime, "weeks") + 1;
      const endWeek = currentTime.diff(endCustomTime, "weeks") + 1;
      const customTimeRangeProperty = {
        "1W-4W": false,
        "5W-8W": false,
        "9W-12W": false,
      };
      // Divide 12 weeks into 4-week intervals and count the number of occurrences that the time
      // ranges 1 to 4, 5 to 6, 9 to 12 weeks are used, respectively.
      if (endWeek <= 4) {
        customTimeRangeProperty["1W-4W"] = true;
        if (startWeek > 4) {
          customTimeRangeProperty["5W-8W"] = true;
        }
      } else if (endWeek <= 8) {
        customTimeRangeProperty["5W-8W"] = true;
        if (startWeek > 8) {
          customTimeRangeProperty["9W-12W"] = true;
        }
      } else {
        customTimeRangeProperty["9W-12W"] = true;
      }
      datadogRum.setGlobalContextProperty("customTimeRange", customTimeRangeProperty);
    } else {
      datadogRum.removeGlobalContextProperty("customTimeRange");
    }
  }
};
