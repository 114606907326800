// import "@cisco-dna/css/css/dnac-css.min.css";

import "dnx-web-components/dist/commons.min.js";
import "dnx-web-components/dist/index-with-deps.min.js";
import "dnx-web-components/dist/index-chunks.min.js";

import "dnx-web-components/vendor/amcharts/amcharts.js";
import "dnx-web-components/vendor/amcharts/serial.js";
import "dnx-web-components/vendor/amcharts/pie.js";

//import "@harbor/elements/dist/harbor-elements/styles/base.css";

// import "@harbor/elements/dist/harbor-elements/styles/themes/magnetic.css";

import "dnx-web-components/dist/styles/magneticstyles.less.css";

export * from "dnx-web-components/dist/index-with-deps-react.min.js";
export * from "dnx-web-components/dist/index-chunks-react.min.js";
