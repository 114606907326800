import React, { useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import { PropTypes } from "prop-types";
import ApiService from "../config/api-config";
import { deleteCookie } from "../serviceWorker";

const OktaSignInWidget = props => {
  const widgetRef = useRef();
  useEffect(() => {
    deleteCookie("prompt-login");
    if (!widgetRef.current) {
      return false;
    }

    const widget = new OktaSignIn(props.oktaIdpConfig);
    const signInwidgetwithoutIDP = new OktaSignIn(props.oktanonIdpConfig);
    widget.renderEl({
      el: widgetRef.current
    });

    widget.on("ready", function() {
      let signInButton = document.getElementById("idp-discovery-submit") || {};
      signInButton.value = "Sign In";
      idpNavigation();
    });

    async function idpNavigation() {
      document
        .getElementById("idp-discovery-submit")
        .addEventListener("click", function(e) {
          let givenUsername = document.querySelector('input[name="username"]')
            .value;
          const propsIdpConfig = props.oktaIdpConfig;
          if (givenUsername !== "") {
            OktaWebFingerCall(
              givenUsername,
              propsIdpConfig,
              e,
              signInwidgetwithoutIDP,
              widget
            );
          }
        });
    }

    return () => widget.remove();
  }, []);

  const OktaWebFingerCall = async (
    username,
    propsIdpConfig,
    e,
    signInwidgetwithoutIDP,
    widget
  ) => {
    e.stopPropagation();
    e.preventDefault();
    let res = await ApiService.oktaWebFinger(username.replace("@", "%40"));
    let oktaSignInFlow = res.data.links[0].properties["okta:idp:type"];
    if (oktaSignInFlow == "OKTA") {
      widget.remove();
      signInwidgetwithoutIDP.renderEl({ el: widgetRef.current }, res => {
        if (res.status === "SUCCESS") {
          signInwidgetwithoutIDP.remove();
        }
      });
    } else {
      let url = propsIdpConfig.authParams.issuer + "/v1/authorize?";
      let client_id = "client_id=" + propsIdpConfig.clientId + "&";
      let params =
        "display=" +
        propsIdpConfig.authParams.display +
        "&nonce=" +
        propsIdpConfig.nonce +
        "&redirect_uri=" +
        propsIdpConfig.redirectUri
          .replaceAll("/", "%2F")
          .replaceAll(":", "%3A") +
        "&response_type=" +
        propsIdpConfig.authParams.responseType +
        "&";
      let idp = "idp=" + res.data.links[0].properties["okta:idp:id"] + "&";
      let state = "state=" + props.token + "&";
      let scope = "scope=" + propsIdpConfig.authParams.scopes.join("%20") + "&";
      let login_hint = "login_hint=" + username.replace("@", "%40");
      let oktaWebFingerLogin = url
        .concat(client_id)
        .concat(params)
        .concat(idp)
        .concat(state)
        .concat(scope)
        .concat(login_hint);
      window.location.href = oktaWebFingerLogin;
    }
  };

  return <div ref={widgetRef} />;
};

OktaSignInWidget.propTypes = {
  token: PropTypes.string,
  oktaIdpConfig: PropTypes.object,
  oktanonIdpConfig: PropTypes.object
};

export default OktaSignInWidget;
